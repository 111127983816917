import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import HomeView from '../views/HomeView.vue';
import LoginPage from '../views/LoginPage.vue';
import UserForm from '../views/UserForm.vue';
import UserView from '@/views/UserView.vue';
import MyProfile from '@/views/MyProfile.vue';
import RoleForm from '@/views/RoleForm.vue';
import RoleView from '@/views/RoleView.vue';
import ApprovalForm from '@/views/ApprovalForm.vue';
import ApprovalView from '@/views/ApprovalView.vue';
import CoverageView from '@/views/CoverageView.vue';
import CoverageForm from '@/views/CoverageForm.vue';
import TransactionTypeView from '@/views/TransactionTypeView.vue';
import TransactionTypeForm from '@/views/TransactionTypeForm.vue';
import ApprovalDetail from '@/views/ApprovalDetail.vue';
import UserDetail from '@/views/UserDetail.vue';
import RoleDetail from '@/views/RoleDetail.vue';
import RollingForecastView from '@/views/RollingForecastView.vue';
import RollingForecastForm from '@/views/RollingForecastForm.vue';
import RollingForecastDetail from '@/views/RollingForecastDetail.vue';
import CmoView from '@/views/CmoView.vue';
import CmoForm from '@/views/CmoForm.vue';
import UOMView from '@/views/UOMView';
import UOMForm from '@/views/UOMForm';
import UOMDetail from '@/views/UOMDetail';
import PriceView from '@/views/PriceView';
import PriceForm from '@/views/PriceForm';
import PriceDetail from '@/views/PriceDetail';
import DistributorView from '@/views/DistributorView';
import DistributorForm from '@/views/DistributorForm';
import DistributorDetail from '@/views/DistributorDetail';
import ProductView from '@/views/ProductView';
import ProductForm from '@/views/ProductForm';
import ProductDetail from '@/views/ProductDetail';
import ProductCategoryView from '@/views/ProductCategoryView';
import ProductCategoryForm from '@/views/ProductCategoryForm';
import ProductCategoryDetail from '@/views/ProductCategoryDetail';
import ProductDivisionView from '@/views/ProductDivisionView';
import ProductDivisionForm from '@/views/ProductDivisionForm';
import ProductDivisionDetail from '@/views/ProductDivisionDetail';
import PeriodView from '@/views/PeriodView.vue';
import PeriodForm from '@/views/PeriodForm.vue';
import SalesTargetView from '@/views/SalesTargetView';
import SalesTargetForm from '@/views/SalesTargetForm';
import SalesTargetDetail from '@/views/SalesTargetDetail';
import DefiasiLimitView from '@/views/DefiasiLimitView';
import DefiasiLimitForm from '@/views/DefiasiLimitForm';
import DefiasiLimitDetail from '@/views/DefiasiLimitDetail';
import SellOutView from '@/views/SellOutView';
import SellOutForm from '@/views/SellOutForm';
import SellOutDetail from '@/views/SellOutDetail';
import SellOutImport from '@/views/SellOutImport';
import ApprovalNeededView from '@/views/ApprovalNeededView';
import ApprovalNeededDetail from '@/views/ApprovalNeededDetail';
import NotificationView from '@/views/NotificationView';
import NotificationDetail from '@/views/NotificationDetail';
import SellInView from '@/views/SellInView.vue';
import SellInForm from '@/views/SellInForm.vue';
import SellInDetail from '@/views/SellInDetail.vue';
import SellInImport from '@/views/SellInImport';
import VehicleForm from '@/views/VehicleForm';
import VehicleDetail from '@/views/VehicleDetail';
import VehicleView from '@/views/VehicleView';
import CalendarManagerForm from '@/views/CalendarManagerForm';
import CalendarManagerView from '@/views/CalendarManagerView';
import PromotionManagerForm from '@/views/PromotionManagerForm';
import PromotionManagerView from '@/views/PromotionManagerView';
import RollingForecastConfiguration from '@/views/RollingForecastConfiguration.vue';
import ProductGroupDetail from '@/views/ProductGroupDetail.vue';
import ProductGroupForm from '@/views/ProductGroupForm.vue';
import ProductGroupView from '@/views/ProductGroupView.vue';
import DefiasiLimitImport from '@/views/DefiasiLimitImport.vue';
import SalesTargetImport from '@/views/SalesTargetImport.vue';
import RofoOpeningBalanceView from '@/views/RofoOpeningBalanceView';
import RofoOpeningBalanceForm from '@/views/RofoOpeningBalanceForm';
import RofoOpeningBalanceImport from '@/views/RofoOpeningBalanceImport';
import CmoDetail from '@/views/CmoDetail.vue';

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'profile',
        name: 'profile',
        component: MyProfile,
        meta: { requiresAuth: true }
      },
      {
        path: 'user',
        name: 'user',
        component: UserView,
      },
      {
        path: 'user/:action/:id?',
        name: 'user-form',
        component: UserForm,
        props: true,
      },
      {
        path: 'user/:action(view)/:id?',
        name: 'user-view',
        component: UserDetail,
        props: true,
      },
      {
        path: 'role',
        name: 'role',
        component: RoleView,
      },
      {
        path: 'role/:action/:id?',
        name: 'role-form',
        component: RoleForm,
        props: true,
      },
      {
        path: 'role/:action(view)/:id?',
        name: 'role-view',
        component: RoleDetail,
        props: true,
      },
      {
        path: 'approval',
        name: 'approval',
        component: ApprovalView,
      },
      {
        path: 'approval/:action(view)/:id?',
        name: 'approval-view',
        component: ApprovalDetail,
        props: true,
      },
      {
        path: 'approval/:action/:id?',
        name: 'approval-form',
        component: ApprovalForm,
        props: true,
      },
      {
        path: 'coverage',
        name: 'coverage',
        component: CoverageView,
      },
      {
        path: 'coverage/:action/:id?',
        name: 'coverage-form',
        component: CoverageForm,
        props: true,
      },
      {
        path: 'transactionType',
        name: 'transactionType',
        component: TransactionTypeView,
      },
      {
        path: 'transactionType/:action/:id?',
        name: 'transactionType-form',
        component: TransactionTypeForm,
        props: true,
      },
      {
        path: 'uom',
        name: 'uom',
        component: UOMView,
      },
      {
        path: 'uom/:action/:id?',
        name: 'uom-form',
        component: UOMForm,
        props: true,
      },
      {
        path: 'uom/:action(view)/:id?',
        name: 'uom-view',
        component: UOMDetail,
        props: true,
      },
      {
        path: 'distributor',
        name: 'distributor',
        component: DistributorView,
      },
      {
        path: 'distributor/:action/:id?',
        name: 'distributor-form',
        component: DistributorForm,
        props: true,
      },
      {
        path: 'distributor/:action(view)/:id?',
        name: 'distributor-view',
        component: DistributorDetail,
        props: true,
      },
      {
        path: 'price',
        name: 'price',
        component: PriceView,
      },
      {
        path: 'price/:action/:id?',
        name: 'price-form',
        component: PriceForm,
        props: true,
      },
      {
        path: 'price/:action(view)/:id?',
        name: 'price-view',
        component: PriceDetail,
        props: true,
      },
      {
        path: 'product',
        name: 'product',
        component: ProductView,
      },
      {
        path: 'product/:action(view)/:id?',
        name: 'product-view',
        component: ProductDetail,
        props: true,
      },
      {
        path: 'product/:action/:id?',
        name: 'product-form',
        component: ProductForm,
        props: true,
      },
      {
        path: 'period',
        name: 'period',
        component: PeriodView,
      },
      {
        path: 'period/:action/:id?',
        name: 'period-form',
        component: PeriodForm,
        props: true,
      },
      {
        path: 'productCategory',
        name: 'productCategory',
        component: ProductCategoryView,
      },
      {
        path: 'productCategory/:action/:id?',
        name: 'productCategory-form',
        component: ProductCategoryForm,
        props: true,
      },
      {
        path: 'productCategory/:action(view)/:id?',
        name: 'productCategory-view',
        component: ProductCategoryDetail,
        props: true,
      },
      {
        path: 'productDivision',
        name: 'productDivision',
        component: ProductDivisionView,
      },
      {
        path: 'productDivision/:action/:id?',
        name: 'productDivision-form',
        component: ProductDivisionForm,
        props: true,
      },
      {
        path: 'productDivision/:action(view)/:id?',
        name: 'productDivision-view',
        component: ProductDivisionDetail,
        props: true,
      },
      {
        path: 'sellOut',
        name: 'sellOut',
        component: SellOutView,
      },
      {
        path: 'sellOut/:action/:id?',
        name: 'sellOut-form',
        component: SellOutForm,
        props: true,
      },
      {
        path: 'sellOut/:action(view)/:id?',
        name: 'sellOut-view',
        component: SellOutDetail,
        props: true,
      },
      {
        path: 'sellOut/:action/:id?',
        name: 'sellOut-import',
        component: SellOutImport,
        props: true,
      },
      {
        path: 'sellIn',
        name: 'sellIn',
        component: SellInView,
      },
      {
        path: 'sellIn/:action/:id?',
        name: 'sellIn-form',
        component: SellInForm,
        props: true,
      },
      {
        path: 'sellIn/:action(view)/:id?',
        name: 'sellIn-view',
        component: SellInDetail,
        props: true,
      },
      {
        path: 'sellIn/:action/:id?',
        name: 'sellIn-import',
        component: SellInImport,
        props: true,
      },
      {
        path: 'defiasiLimit',
        name: 'defiasiLimit',
        component: DefiasiLimitView,
      },
      {
        path: 'defiasiLimit/:action/:id?',
        name: 'defiasiLimit-form',
        component: DefiasiLimitForm,
        props: true,
      },
      {
        path: 'defiasiLimit/:action(view)/:id?',
        name: 'defiasiLimit-view',
        component: DefiasiLimitDetail,
        props: true,
      },
      {
        path: 'defiasiLimit/:action/:id?',
        name: 'defiasiLimit-import',
        component: DefiasiLimitImport,
        props: true,
      },
      {
        path: 'salesTarget',
        name: 'salesTarget',
        component: SalesTargetView,
      },
      {
        path: 'salesTarget/:action/:id?',
        name: 'salesTarget-form',
        component: SalesTargetForm,
        props: true,
      },
      {
        path: 'salesTarget/:action(view)/:id?',
        name: 'salesTarget-view',
        component: SalesTargetDetail,
        props: true,
      },
      {
        path: 'salesTarget/:action/:id?',
        name: 'salesTarget-import',
        component: SalesTargetImport,
        props: true,
      },
      {
        path: 'rofo',
        name: 'rofo',
        component: RollingForecastView,
      },
      {
        path: 'rofo/:action/:id?/:type?',
        name: 'rofo-form',
        component: RollingForecastForm,
        props: true,
      },
      {
        path: 'rofo/:action(view)/:id?/:type?',
        name: 'rofo-view',
        component: RollingForecastDetail,
        props: true,
      },
      {
        path: 'cmo',
        name: 'cmo',
        component: CmoView,
      },
      {
        path: 'cmo/:action/:id?',
        name: 'cmo-form',
        component: CmoForm,
        props: true,
      },
      {
        path: 'cmo/:action(view)/:id?/',
        name: 'cmo-view',
        component: CmoDetail,
        props: true,
      },
      {
        path: 'approvalNeeded/:action(view)/:id?',
        name: 'approvalNeeded-view',
        component: ApprovalNeededDetail,
        props: true,
      },
      {
        path: 'approvalNeeded',
        name: 'approvalNeeded',
        component: ApprovalNeededView,
      },
      {
        path: 'notification/:action(view)/:id?',
        name: 'notification-view',
        component: NotificationDetail,
        props: true,
      },
      {
        path: 'notification',
        name: 'notification',
        component: NotificationView,
      },
      {
        path: 'vehicle/:action(view)/:id?',
        name: 'vehicle-view',
        component: VehicleDetail,
        props: true,
      },
      {
        path: 'vehicle/:action/:id?',
        name: 'vehicle-form',
        component: VehicleForm,
        props: true,
      },
      {
        path: 'vehicle',
        name: 'vehicle',
        component: VehicleView,
      },
      {
        path: 'calendar/:action/:id?',
        name: 'calendar-form',
        component: CalendarManagerForm,
        props: true,
      },
      {
        path: 'calendar',
        name: 'calendar',
        component: CalendarManagerView,
      },
      {
        path: 'promotion/:action/:id?',
        name: 'promotion-form',
        component: PromotionManagerForm,
        props: true,
      },
      {
        path: 'promotion',
        name: 'promotion',
        component: PromotionManagerView,
      },
      {
        path: 'rollingForecastConfiguration',
        name: 'rollingForecastConfiguration',
        component: RollingForecastConfiguration,
      },
      {
        path: 'productGroup/:action(view)/:id?',
        name: 'productGroup-view',
        component: ProductGroupDetail,
        props: true,
      },
      {
        path: 'productGroup/:action/:id?',
        name: 'productGroup-form',
        component: ProductGroupForm,
        props: true,
      },
      {
        path: 'productGroup',
        name: 'productGroup',
        component: ProductGroupView,
      },
      {
        path: 'rofoOpeningBalance',
        name: 'rofoOpeningBalance',
        component: RofoOpeningBalanceView,
      },
      {
        path: 'rofoOpeningBalance/:action/:id?',
        name: 'rofoOpeningBalance-form',
        component: RofoOpeningBalanceForm,
        props: true,
      },
      {
        path: 'rofoOpeningBalance/:action/:id?',
        name: 'rofoOpeningBalance-import',
        component: RofoOpeningBalanceImport,
        props: true,
      }
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Add navigation guard
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('authToken');
  store.dispatch('showLoading')

  if (to.name && from.name && (to.name.includes(from.name) || from.name.includes(to.name))) {
    // If both contain similar words, do not dispatch the alert
  } else {
    // Otherwise, hide the alert
    store.dispatch('hideAlert');
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next({ name: 'login' });
    } else {
      next();
    }
  } else {
    next();
  }
});


router.afterEach((to, from, next) => {
  setTimeout(() => store.dispatch('hideLoading'), 200);
  
})

export default router;
