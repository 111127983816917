<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title> </template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Product Division</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-select v-model="product.product_division_id" :items="productDivision" item-value="id" item-title="name"
                    required></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6"></v-col>
          </v-row>
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Product Category 1</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-select :item-props="itemPropsProductCategory1" v-model="product.product_category_id" :items="productCategoryParent" item-value="id" item-title="name"
                    required></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Product Category 2</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-select :item-props="itemPropsProductCategory2" v-model="product.product_category_2_id" :items="productCategory" item-value="id" item-title="name"
                    required></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Product Number 1</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="product.sku" required></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Product Number 2</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="product.barcode" required></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Product Description</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-textarea rows="2" v-model="product.description" required></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Product Nickname</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="product.product_name" required></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">UOM</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-select v-model="product.uom_id" :items="uom" item-value="id" item-title="name"
                    required></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
            </v-col>
          </v-row>
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Status</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-select v-model="product.status" :items="statusOptions"
                    required></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
            </v-col>
          </v-row>
          <!-- Product General Dimension -->
          <v-row>
            <v-col>
              <h4>Product General Dimension</h4>
            </v-col>
          </v-row>
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Product Group</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-radio-group
                    v-model="product.product_group_id"
                    inline
                    hide-details
                  >
                    <v-radio
                      v-for="group in productGroup"
                      :key="group.id"
                      :label="group.name"
                      :value="group.id"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Volume</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-row>
                    <v-col cols="6">
                      <vue-number v-model="product.general_dimension_volume" v-bind="number" class="custom-input"></vue-number>
                    </v-col>
                    <v-col cols="6">
                      <v-select v-model="product.general_dimension_volume_uom_id" :items="uom" item-value="id" item-title="name"
                        required placeholder="Select UOM"></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Nett Weight</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-row>
                    <v-col cols="6">
                      <vue-number v-model="product.general_dimension_net_weight" v-bind="number" class="custom-input"></vue-number>
                    </v-col>
                    <v-col cols="6">
                      <v-select v-model="product.general_dimension_net_weight_uom_id" :items="uom" item-value="id" item-title="name"
                        required placeholder="Select UOM"></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Gross Weight</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-row>
                    <v-col cols="6">
                      <vue-number v-model="product.general_dimension_gross_weight" v-bind="number" class="custom-input"></vue-number>
                    </v-col>
                    <v-col cols="6">
                      <v-select v-model="product.general_dimension_gross_weight_uom_id" :items="uom" item-value="id" item-title="name"
                        required placeholder="Select UOM"></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Qty in Car</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <vue-number v-model="product.general_dimension_qty_in_car" v-bind="number" class="custom-input"></vue-number>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Product Inner Dimension -->
          <v-row>
            <v-col>
              <h4>Product Inner Dimension</h4>
            </v-col>
          </v-row>
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Size/Dimension</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-checkbox
                    v-model="innerDimensionSetManual"  
                    color="primary"
                    label="Set Manual Dimensions"
                    value="primary"
                    large
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label"></label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-row>
                    <v-col cols="3">
                      <vue-number :disabled="!innerDimensionSetManual" v-model="product.inner_dimension_length" v-bind="number" class="custom-input" placeholder="L"></vue-number>
                    </v-col>
                    <v-col cols="3">
                      <vue-number :disabled="!innerDimensionSetManual" v-model="product.inner_dimension_width" v-bind="number" class="custom-input" placeholder="W"></vue-number>
                    </v-col>
                    <v-col cols="3">
                      <vue-number :disabled="!innerDimensionSetManual" v-model="product.inner_dimension_height" v-bind="number" class="custom-input" placeholder="H"></vue-number>
                    </v-col>
                    <v-col cols="3">
                      <v-select :disabled="!innerDimensionSetManual" v-model="product.inner_dimension_uom_id" :items="uom" item-value="id" item-title="name"
                        required placeholder="UOM"></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Volume</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-row>
                    <v-col cols="6">
                      <vue-number v-model="product.inner_dimension_volume" v-bind="number" class="custom-input"></vue-number>
                    </v-col>
                    <v-col cols="6">
                      <v-select v-model="product.inner_dimension_volume_uom_id" :items="uom" item-value="id" item-title="name"
                        required placeholder="Select UOM"></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

           <!-- Product Outer Dimension -->
           <v-row>
            <v-col>
              <h4>Product Outer Dimension</h4>
            </v-col>
          </v-row>
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Size/Dimension</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-checkbox
                    v-model="outerDimensionSetManual"  
                    color="primary"
                    label="Set Manual Dimensions"
                    value="primary"
                    large
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label"></label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-row>
                    <v-col cols="3">
                      <vue-number :disabled="!outerDimensionSetManual" v-model="product.outer_dimension_length" v-bind="number" class="custom-input" placeholder="L"></vue-number>
                    </v-col>
                    <v-col cols="3">
                      <vue-number :disabled="!outerDimensionSetManual" v-model="product.outer_dimension_width" v-bind="number" class="custom-input" placeholder="W"></vue-number>
                    </v-col>
                    <v-col cols="3">
                      <vue-number :disabled="!outerDimensionSetManual" v-model="product.outer_dimension_height" v-bind="number" class="custom-input" placeholder="H"></vue-number>
                    </v-col>
                    <v-col cols="3">
                      <v-select :disabled="!outerDimensionSetManual" v-model="product.outer_dimension_uom_id" :items="uom" item-value="id" item-title="name"
                        required placeholder="UOM"></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Volume</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-row>
                    <v-col cols="6">
                      <vue-number v-model="product.outer_dimension_volume" v-bind="number" class="custom-input"></vue-number>
                    </v-col>
                    <v-col cols="6">
                      <v-select v-model="product.outer_dimension_volume_uom_id" :items="uom" item-value="id" item-title="name"
                        required placeholder="Select UOM"></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          
        </v-form>
      </div>
    </v-card-text>

    <template v-slot:append>
      <v-btn @click="submitForm" color="primary">
        {{ isAdding ? $t('button.save') : $t('button.update') }}
      </v-btn>
    </template>
  </v-card>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
  components: {
    Breadcrumbs
  },
  data() {
    return {
      statusOptions: ['Active', 'Inactive'],
      product: {
        id: null,
        sku: '',
        barcode: '',
        product_name: '',
        alias_name: '',
        description: '',
        status: 'Active',
        
        // Product categories and division
        product_category_id: null,
        product_category_2_id: null,
        product_division_id: null,
        product_group_id: null,

        // Unit of Measure
        uom_id: null,

        // General Dimensions
        general_dimension_volume: null,
        general_dimension_net_weight: null,
        general_dimension_gross_weight: null,
        general_dimension_qty_in_car: null,
        general_dimension_volume_uom_id: null,
        general_dimension_net_weight_uom_id: null,
        general_dimension_gross_weight_uom_id: null,

        // Inner Dimensions
        inner_dimension_manual_entry: false,
        inner_dimension_length: null,
        inner_dimension_width: null,
        inner_dimension_height: null,
        inner_dimension_volume: null,
        inner_dimension_uom_id: null,
        inner_dimension_volume_uom_id: null,

        // Outer Dimensions
        outer_dimension_manual_entry: false,
        outer_dimension_length: null,
        outer_dimension_width: null,
        outer_dimension_height: null,
        outer_dimension_volume: null,
        outer_dimension_uom_id: null,
        outer_dimension_volume_uom_id: null
      },
      productDivision: [],
      productCategory: [],
      productGroup: [],
      productCategoryParent: [],
      uom: [],
      innerDimensionSetManual: false,
      outerDimensionSetManual: false,
    };
  },
  computed: {
    isAdding() {
      return this.$route.params.action === 'add';
    },
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    },
     // Dynamic Nett Weight
     dynamicWeight: {
      get() {
        return this.getSizeField('weight');
      },
      set(value) {
        this.setSizeField('weight', value);
      }
    },
    // Dynamic Volume
    dynamicVolume: {
      get() {
        return this.getSizeField('volume');
      },
      set(value) {
        this.setSizeField('volume', value);
      }
    },
    // Dynamic Length
    dynamicLength: {
      get() {
        return this.getSizeField('length');
      },
      set(value) {
        this.setSizeField('length', value);
      }
    },
    // Dynamic Height
    dynamicHeight: {
      get() {
        return this.getSizeField('height');
      },
      set(value) {
        this.setSizeField('height', value);
      }
    },
  },
  watch: {
    'product.inner_dimension_length': 'calculateInnerVolume',
    'product.inner_dimension_width': 'calculateInnerVolume',
    'product.inner_dimension_height': 'calculateInnerVolume',
    'product.outer_dimension_length': 'calculateOuterVolume',
    'product.outer_dimension_width': 'calculateOuterVolume',
    'product.outer_dimension_height': 'calculateOuterVolume'
  },
  async mounted() {
    const { action, id } = this.$route.params;

    this.fetchCategory();
    this.fetchProductDivision();
    this.fetchProductGroup();
    this.fetchUOM();
    this.fetchCategoryParent();

    // Fetch Product details if editing
    if (action === 'edit' && id) {
      try {
        const response = await axios.get(`/product/${id}`);
        this.product = response.data;
        // this.selectedProductId = this.Product.parent; // Set selectedproductId based on Product data
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async handleSubmit() {
      try {

        if (this.isAdding) {
          const response = await axios.post('/product/', { product: this.product });

          if (response.data) {
            this.$router.push({ name: 'product' });
            this.showAlert({ message: 'Successfully added product data', color: 'success' });
          } else {
            this.showAlert({ message: "Failed", color: 'error' });
          }
        } else {
          const response = await axios.put(`/product/${this.product.id}`, { product: this.product });

          if (response.data) {
            this.$router.push({ name: 'product' });
            this.showAlert({ message: 'Successfully added product data', color: 'success' });
          } else {
            this.showAlert({ message: 'Error updating Product', color: 'error' });
          }
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    submitForm() {
      this.$refs.form.validate(); // Trigger form validation
      this.handleSubmit(); // Call the submit method
    },
    async fetchCategory() {
      this.loading = true;
      try {
        const response = await axios.get('/product_category?is_parent=false');
        this.productCategory = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchCategoryParent() {
      this.loading = true;
      try {
        const response = await axios.get('/product_category?is_parent=true');
        this.productCategoryParent = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchProductDivision() {
      this.loading = true;
      try {
        const response = await axios.get('/product_division');
        this.productDivision = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchProductGroup() {
      this.loading = true;
      try {
        const response = await axios.get('/product_group');
        this.productGroup = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchUOM() {
      this.loading = true;
      try {
        const response = await axios.get('/uom');
        this.uom = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    getSizeField(field) {
      // Return the appropriate field based on size_type
      if (this.product.size_type === 'small') {
        return this.product[`small_${field}`];
      } else if (this.product.size_type === 'medium') {
        return this.product[`medium_${field}`];
      } else if (this.product.size_type === 'large') {
        return this.product[`large_${field}`];
      }
    },
    setSizeField(field, value) {
      // Set the appropriate field based on size_type
      if (this.product.size_type === 'small') {
        this.product[`small_${field}`] = value;
      } else if (this.product.size_type === 'medium') {
        this.product[`medium_${field}`] = value;
      } else if (this.product.size_type === 'large') {
        this.product[`large_${field}`] = value;
      }
    },
    calculateInnerVolume() {
      const { inner_dimension_length: l, inner_dimension_width: w, inner_dimension_height: h } = this.product;
      this.product.inner_dimension_volume = (l && w && h) ? l * w * h : null;
    },
    calculateOuterVolume() {
      const { outer_dimension_length: l, outer_dimension_width: w, outer_dimension_height: h } = this.product;
      this.product.outer_dimension_volume = (l && w && h) ? l * w * h : null;
    },
    itemPropsProductCategory1 (item) {
      return {
        title: item.name,
        subtitle: 'Code : ' + item.code,
      }
    },
    itemPropsProductCategory2 (item) {
      return {
        title: item.name,
        subtitle: 'Code : ' + item.code,
      }
    },
  }
};
</script>

<style scope>
.custom-input {
	border: 1px solid black;
	padding: 6px 6px;
	border-radius: 2px;
	width: 100%;
	box-sizing: border-box;
	text-align: center;
}
</style>