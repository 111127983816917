<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-row>
        <v-col cols="12" class="d-flex justify-end">
            <v-btn color="primary" @click="navigateToForm('add')">{{ $t('button.newCmo') }}</v-btn>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-layout class="overflow-visible" style="height: 40px;">
                <v-bottom-navigation v-model="value" active>
                    <v-btn @click="fetchCMO('Direct (MT)')">Direct (MT)</v-btn>
                    <v-btn @click="fetchCMO('Distributor (GT)')">Distributor (GT)</v-btn>
                    <v-btn @click="fetchCMO('CBD')">CBD</v-btn>
                </v-bottom-navigation>
            </v-layout>
        </v-col>
    </v-row>
    <!-- <v-row class="d-flex flex-row-reverse"> -->
    <v-row>
        <v-col cols="2">
            <v-select bg-color="white" v-model="filter.type" :items="cmoTypes" label="Type"></v-select>
        </v-col>
        <v-col cols="2">
            <v-text-field bg-color="white" placeholder="" density="compact" v-model="filterParams"
                append-inner-icon="mdi-magnify"></v-text-field>
        </v-col>
        <v-col cols="2">
            <v-text-field
              bg-color="white"
              v-model="filter.start"
              label="Start date"
              type="date"
            ></v-text-field>
        </v-col>
        <v-col cols="2">
            <v-text-field
              bg-color="white"
              v-model="filter.end"
              label="End date"
              type="date"
            ></v-text-field>
        </v-col>
        <v-col cols="2">
            <v-select bg-color="white" v-model="filter.status" :items="statusOption" label="Status"></v-select>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
            <data-table :headers="headers" :items="cmo" :actions="tableActions" />
        </v-col>
    </v-row>

    <v-overlay
			:model-value="loading"
        class="align-center justify-center"
        persistent
    >
        <v-progress-circular
            color="primary"
            size="120"
            indeterminate
        ></v-progress-circular>
    </v-overlay>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig';
import { mapActions } from 'vuex';
import DataTable from '@/components/DataTable.vue';

export default {
    components: {
        Breadcrumbs,
        DataTable
    },
    data() {
        return {
            value: 0,
            loading: false,
            filter : {
                type: '',
            },
            cmoTypes: ['Full Shipment', 'Multi Drop'],
            statusOption: ['Awaiting Approval', 'Draft', 'Submitted', 'Approved'],
            headers: [
                { title: 'Transaction Number', key: 'transaction_number', align: 'start', clickable: true },
                { title: 'Transaction Date', key: 'transaction_date', align: 'start' },
                { title: 'Shipment Type', key: 'shipment_type', align: 'start' },
                { title: 'Purchase Order Number', key: 'purchase_order_number', align: 'start' },
                { title: 'Status', key: 'status', align: 'start' },
            ],
            cmo: [],
            tableActions: {
                // edit: this.navigateToForm,
                // delete: this.deleteUser,
                view: this.navigateToForm
            }
        };
    },
    computed: {
        breadcrumbItems() {
            const route = this.$route.name;
            const action = this.$route.params.action;
            const breadcrumbItems = breadcrumbConfig[route];

            // Handle case where the route is dynamic, e.g., 'user-form'
            if (typeof breadcrumbItems === 'function') {
                return breadcrumbItems(action);
            }

            return breadcrumbItems || [];
        }
    },
    methods: {
        ...mapActions(['showAlert']),
        async fetchCMO() {
            this.loading = true;
            try {
                const response = await axios.get('/confirm_monthly_order');
                this.cmo = response.data;
                this.loading = false;
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
                this.loading = false;
            }
        },
        navigateToForm(action, cmo = { id: null }) {
            if (action == 'view') {
                this.$router.push({ name: 'cmo-view', params: { action, id: cmo.id } });
            } else {
                this.$router.push({ name: 'cmo-form', params: { action, id: cmo.id } });
            }
        },
        async deleteUser(userToDelete) {
            try {
                const response = await axios.delete(`/cmo/${userToDelete.id}`);

                if (response.data) {
                    this.cmo = this.cmo.filter(u => u.id !== userToDelete.id);

                    this.showAlert({ message: 'Success', color: 'success' });
                } else {
                    throw new Error('Failed to delete user');
                }
            } catch (error) {
                const errorMessage = error.response?.data?.messages
                    ? error.response.data.messages
                    : error.response?.message || 'An error occurred';

                this.showAlert({ message: errorMessage, color: 'error' });
            }
        }
    },
    mounted() {
        this.fetchCMO();
    }
};
</script>

<style scoped>
::v-deep .v-bottom-navigation__content {
    justify-content: left !important;
}
</style>