<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title>Vehicle Information</template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <!-- Row 1 -->
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Vehicle Code</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="vehicle.vehicle_code" required></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Vehicle Name</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="vehicle.vehicle_name" required></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Row 2 -->
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Loading Ratio (in CRT)</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="vehicle.loading_ratio_in_crt"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Volume</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="vehicle.volume" required></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Row 3 -->
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Opt.Kubikasi(%)</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="vehicle.opt_cubication" hint="Merupakan limit fullfilment yang akan diterapkan dalam transaksi CMO" persistent-hint :hide-details="false"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Weight</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="vehicle.weight"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Row 4 -->
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Conversion to Bag</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="vehicle.conversion_to_bag"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Area Coverage</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-textarea v-model="vehicle.area_coverage"></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

           <!-- Row 4 -->
           <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Status</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-select v-model="vehicle.status" :items="statusOption"></v-select>
                </v-col>
              </v-row>
            </v-col>
           
          </v-row>

          <!-- Row 5 -->
          <v-row class="my-2">
            <v-col cols="12">
              <div>Proposed Standardization</div>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Weight</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="vehicle.propose_standardization_weight"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Volume</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="vehicle.propose_standardization_volume"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Row 6 -->
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Loading Capacity (330 ml)</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="vehicle.propose_standardization_loading_capacity"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Loading Capacity (Bags)</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="vehicle.propose_standardization_loading_capacity_in_bags"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Row 7 -->
          <v-row class="my-2">
            <v-col cols="12">
              <div>Loading Capacity Base in Bags</div>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Weight (Kg)</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="vehicle.loading_capacity_in_bags_weight"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">volume (dm3)</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="vehicle.loading_capacity_in_bags_volume"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Row 8 -->
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Opt. Kubikasi (%)</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="vehicle.loading_capacity_in_bags_opt_cubication" ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card-text>

    <template v-slot:append>
      <v-btn @click="submitForm" color="primary">
        {{ isAdding ? $t('button.save') : $t('button.update') }}
      </v-btn>
    </template>
  </v-card>
</template>

  <script>
  import axios from '../plugins/axios';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import { mapActions } from 'vuex';
  import breadcrumbConfig from '@/others/breadcrumbConfig.js';
  
  export default {
    components: {
      Breadcrumbs
    },
    data() {
      return {
        vehicle: {
          id: null,
          vehicle_code: '',
          vehicle_name: '',
          loading_ratio_in_crt: null,
          volume: null,
          opt_cubication: null,
          weight: null,
          conversion_to_bag: null,
          area_coverage: '',
          propose_standardization_weight: null,
          propose_standardization_volume: null,
          propose_standardization_loading_capacity: null,
          propose_standardization_loading_capacity_in_bags: null,
          loading_capacity_in_bags_weight: null,
          loading_capacity_in_bags_volume: null,
          loading_capacity_in_bags_opt_cubication: null,
          status: 'Active',
      },
        statusOption: ['Active', 'Inactive'],
        selectedRoleId: null,
        selectedProductId: null,
      };
    },
    computed: {
      isAdding() {
        return this.$route.params.action === 'add';
      },
      breadcrumbItems() {
        const action = this.$route.params.action;
        const route = this.$route.name;
        const items = breadcrumbConfig[route];
  
        if (typeof items === 'function') {
          return items(action);
        }
  
        return items || [];
      }
    },
    async mounted() {
      const { action, id } = this.$route.params;
      // Fetch vehicle details if editing
      if (action === 'edit' && id) {
        try {
          const response = await axios.get(`/vehicle/${id}`);
          this.vehicle = response.data;
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      }
    },
    methods: {
      ...mapActions(['showAlert']),
      async handleSubmit() {
        try {
          
          if (this.isAdding) {
            const response = await axios.post('/vehicle',  this.vehicle );
            this.showAlert({ message: 'vehicle data successfully saved', color: 'success' });
            if (response.data) {
              this.$router.push({ name: 'vehicle' });
            } else {
              throw new Error(response.message);
            }
          } else {
            const response = await axios.put(`/vehicle/${this.vehicle.id}`, this.vehicle );
            this.showAlert({ message: 'vehicle data successfully update', color: 'success' });
            if (response.data) {
              this.$router.push({ name: 'vehicle' });
            } else {
              throw new Error('Failed to update vehicle');
            }
          }
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      submitForm() {
        this.$refs.form.validate(); // Trigger form validation
        this.handleSubmit(); // Call the submit method
      },
      itemProps (item) {
        return {
          title: item.product_name,
          subtitle: item.sku,
        }
      },
    }
  };
  </script>