<template>
	<Breadcrumbs :items="breadcrumbItems" />
	<v-card>
		<template v-slot:title>
			<!-- Show Head Tabs if the rofo type is NOT District -->
			<v-row class="py-3" v-if="rofoType !== 'District'">
				<v-tabs v-model="selectedHeadTab" bg-color="white" show-arrows
				@update:modelValue="(value) => updateHeadTabValue(value, headTabsData.findIndex(tab => tab === value))">
					<v-tab v-for="(data, index) in headTabsData" :key="data" :text="data.name"
						:value="data"></v-tab>
				</v-tabs>
			</v-row>
			<div class="text-caption pt-4">{{ rollingForecastParams.status }}</div>
			
		</template>

		<v-divider></v-divider>

		<v-card-text>
			<div>
				<v-form ref="form" @submit.prevent="handleSubmit">
					<v-row>
						<v-col md="4" sm="4" cols="12">
							<v-row>
								<v-col>
									<label class="custom-label">Transaction Type</label>
								</v-col>
								<v-col>
									<v-select :readonly="mode === 0 && selectedHeadTab !== 0" v-model="rollingForecastParams.transaction_type_id"
										:items="transactionTypes" item-value="id" item-title="name" required></v-select>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row>
						<v-col md="4" sm="4" cols="12">
							<v-row>
								<v-col>
									<label class="custom-label">Transaction Number</label>
								</v-col>
								<v-col>
									<v-text-field readonly placeholder="Autonumber"
										v-model="rollingForecastParams.transaction_number" required></v-text-field>
								</v-col>
							</v-row>
						</v-col>
						<v-col md="4" sm="4" cols="12">
							<v-row>
								<v-col>
									<label class="custom-label">Area</label>
								</v-col>
								<v-col>
									<v-text-field :readonly="mode === 0" v-model="rollingForecastParams.area"
										required></v-text-field>
								</v-col>
							</v-row>
						</v-col>
						<v-col md="4" sm="4" cols="12">
							<v-row>
								<v-col>
									<label class="custom-label">Transaction Period</label>
								</v-col>
								<v-col>
									<v-select readonly v-model="rollingForecastParams.period_id"
										:items="periods" item-value="id" item-title="title" required></v-select>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row>
						<v-col md="4" sm="4" cols="12">
							<v-row>
								<v-col>
									<label class="custom-label">Transaction Date</label>
								</v-col>
								<v-col>
									<v-text-field readonly v-model="rollingForecastParams.transaction_date"
										required></v-text-field>
								</v-col>
							</v-row>
						</v-col>
						<v-col md="4" sm="4" cols="12">
							<v-row>
								<v-col>
									<label class="custom-label">Region</label>
								</v-col>
								<v-col>
									<v-text-field readonly v-model="rollingForecastParams.region"
										required></v-text-field>
								</v-col>
							</v-row>
						</v-col>
						<v-col md="4" sm="4" cols="12">
							<v-row>
								<v-col>
									<label class="custom-label">View Mode</label>
								</v-col>
								<v-col>
									<v-select disabled :readonly="mode === 0 && selectedHeadTab !== 0" v-model="rollingForecastParams.view_mode"
										:items="transactionTypes" item-value="id" item-title="name" required></v-select>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-form>
			</div>
		</v-card-text>

		<template v-slot:append>
			<v-btn color="primary" class="ml-2" @click="submitForm(2)" v-if="asApprover">Approve</v-btn>
			<v-btn color="primary" class="ml-2" append-icon="mdi-chevron-down" v-if="!asApprover">
				{{ $route.params.action === 'add' ? $t('button.save') : $t('button.update') }}

				<v-menu activator="parent" density="compact">
					<v-list density="compact">
						<v-list-item density="compact" title="Save as draft" value="edit" @click="submitForm(0)"></v-list-item>
						<v-list-item density="compact" title="Save & Submit" value="submit" @click="submitForm(1)"></v-list-item>
					</v-list>
				</v-menu>
			</v-btn>
			<v-btn color="primary" class="ml-2" append-icon="mdi-chevron-down" v-if="asApprover">
				Options

				<v-menu activator="parent" density="compact">
					<v-list density="compact">
						<!-- <v-list-item density="compact" title="Edit" value="edit" @click="updateMode"></v-list-item>
						<v-list-item density="compact" title="Approved" value="submit" @click="submitForm(2)"></v-list-item> -->
						<v-list-item density="compact" title="Request for Revise" value="edit" @click="reviseData" v-if="asApprover"></v-list-item>
					</v-list>
				</v-menu>
			</v-btn>
		</template>
	</v-card>

	<div class="pa-3"></div>

	<v-card>
		<template v-slot:title>
			<v-row>
				<v-tabs v-model="selectedTab" bg-color="white" show-arrows
					@update:modelValue="updateTabValue($event)">
					<v-tab v-for="(data, index) in tabsData" :key="data" :text="data.name"
						:value="(data, index)"></v-tab>
				</v-tabs>
			</v-row>

			<v-row>
				<v-col>
					<v-tooltip v-model="tooltipShow" location="end" theme="light">
						<template v-slot:activator="{ props }">
							<v-btn v-bind="props" class="mx-1" color="primary" variant="outlined"
								prepend-icon="mdi-refresh" @click="populateData">
								Refresh Item
							</v-btn>
						</template>
						<span>Please refresh data if table header doesn't load correctly</span>
					</v-tooltip>

					<v-btn class="mx-1" color="primary" variant="outlined" prepend-icon="mdi-view-list-outline">
						List View
					</v-btn>

					<v-btn class="mx-1" color="primary" variant="outlined" prepend-icon="mdi-fullscreen"
						@click="toggleFullScreen">
						Fullscreen
					</v-btn>
				</v-col>
				<v-spacer></v-spacer>

				<v-col cols="4">
					<v-text-field v-model="search" placeholder="Search" density="compact" append-inner-icon="mdi-magnify"></v-text-field>
				</v-col>
			</v-row>
		</template>

		<v-divider></v-divider>

		<v-card-text>
			<v-data-table v-if="isDataReady && products" hide-default-footer fixed-header :headers="headers"
				:items="products[selectedTab].rolling_forecast_product_details_attributes" item-key="product.id"
				:loading="loading" class="frozen-columns-table" :items-per-page="100" :search="search">
				<template v-slot:item.index="{ index }">
					{{ index + 1 }}
				</template>

				<template v-slot:item.stock="{ item }">
					<!-- <input :readonly="mode === 0" type="text" v-model.number="item.stock"
						@input="updateValue(item, 'doi')" class="custom-input text-center" /> -->
					<div v-if="mode === 0 || selectedTab === 0" class="text-right py-2">
						{{ formatNumber(item.stock) }}
					</div>
					<div v-else>
						<vue-number v-model="item.stock" v-bind="number" @change="updateValue(item, 'doi')" class="custom-input text-right ma-2"></vue-number>
					</div>
				</template>

				<template v-slot:item.avg_sol3m="{ item }">
					<div class="text-right">
						{{ formatNumber(item.avg_sol3m) }}
					</div>
				</template>

				<template v-slot:item.doi="{ item }">
					<div class="text-right">
						{{ formatNumber(item.doi)}}
					</div>
				</template>

				<!-- Define slot for qty_m0 -->
				<template v-slot:item.m0_qty="{ item }">
					<!-- <input :readonly="mode === 0" type="text" v-model.number="item.m0_qty"
						@input="updateValue(item, 'm0')" class="custom-input text-center" /> -->
					<div v-if="mode === 0 || selectedTab === 0" class="text-right">
						{{ formatNumber(item.m0_qty) }}
					</div>
					<div v-else>
						<vue-number v-model="item.m0_qty" v-bind="number" @change="updateValue(item, 'm0')" class="custom-input text-right py-2"></vue-number>
					</div>
				</template>

				<template v-slot:item.m0_defiation="{ item }">
					<div :class="item.m0_defiation > item.m0_defiation ? 'invalid-mark' : ''" class="text-right">
            			{{ formatNumber(item.m0_defiation) + '%' }} 
					</div>
				</template>

				<template v-slot:item.m0_percent_to_target="{ item }">
					<div :class="item.m0_percent_to_target < item.target ? 'invalid-mark' : ''" class="text-right">
            			{{ formatNumber(item.m0_percent_to_target) + '%' }} 
					</div>
				</template>

				<template v-slot:item.m0_est_ytd_actual="{ item }">
          			{{ formatNumber(item.m0_percent_to_target) + '%' }} 
				</template>

				<template v-slot:item.m0_forecast_accuracy_l3m="{ item }">
					{{ formatNumber(item.lm_forecast_accuracy) + '%' }} 
				</template>

				<!-- Define slot for qty_m1 -->
				<template v-slot:item.m1_qty="{ item }">
					<!-- <input :readonly="mode === 0" type="text" v-model.number="item.m1_qty"
						@input="updateValue(item, 'm1')" class="custom-input text-center" /> -->
					<div v-if="mode === 0 || selectedTab === 0" class="text-right">
						{{ formatNumber(item.m1_qty) }}
					</div>
					<div v-else>
						<vue-number v-model="item.m1_qty" v-bind="number" @change="updateValue(item, 'm1')" class="custom-input text-right py-2"></vue-number>
					</div>
				</template>

				<template v-slot:item.m1_defiation="{ item }">
					<div :class="item.m1_defiation > item.m1_defiation ? 'invalid-mark' : ''" class="text-right">
            			{{ formatNumber(item.m1_defiation) + '%' }}  
					</div>
				</template>

				<template v-slot:item.m1_percent_to_target="{ item }">
					<div :class="item.m1_percent_to_target < item.target ? 'invalid-mark' : ''" class="text-right">
            			{{ formatNumber(item.m1_percent_to_target) + '%' }}  
					</div>
				</template>

				<template v-slot:item.m1_est_ytd_actual="{ item }">
          			{{ formatNumber(item.m1_percent_to_target) + '%' }}  
				</template>

				<template v-slot:item.m1_forecast_accuracy_l3m="{ item }">
					{{ formatNumber(item.l2m_forecast_accuracy) + '%' }} 
				</template>

				<!-- Define slot for qty_m2 -->
				<template v-slot:item.m2_qty="{ item }">
					<!-- <input :readonly="mode === 0" type="text" v-model.number="item.m2_qty"
						@input="updateValue(item, 'm2')" class="custom-input text-center" /> -->
					<div v-if="mode === 0 || selectedTab === 0" class="text-right">
						{{ formatNumber(item.m2_qty) }}
					</div>
					<div v-else>
						<vue-number v-model="item.m2_qty" v-bind="number" @change="updateValue(item, 'm2')" class="custom-input text-right py-2"></vue-number>
					</div>
				</template>

				<template v-slot:item.m2_defiation="{ item }">
					<div :class="item.m2_defiation > item.m2_defiation ? 'invalid-mark' : ''" class="text-right">
						{{ formatNumber(item.m2_defiation) + '%' }}  
					</div>
				</template>

				<template v-slot:item.m2_percent_to_target="{ item }">
					<div :class="item.m2_percent_to_target < item.target ? 'invalid-mark' : ''" class="text-right">
						{{ formatNumber(item.m2_percent_to_target) + '%' }}  
					</div>
				</template>

				<template v-slot:item.m2_est_ytd_actual="{ item }">
					{{ formatNumber(item.m2_percent_to_target) + '%' }}  
				</template>

				<template v-slot:item.m2_forecast_accuracy_l3m="{ item }">
					{{ formatNumber(item.l3m_forecast_accuracy) + '%' }} 
				</template>

				<template v-slot:body.append>
					<tr class="elevation-0">
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="fixed-column total-label elevation-0 text-weight-bold">Grand Total</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].stock) }}</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].avg_sol3m) }}</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].doi) + '%'}}</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m0_qty) }}</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m0_defiation) + '%'}}</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m0_percent_to_target) + '%' }}</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m1_qty) }}</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m1_defiation) + '%' }}</td>
						<td class="fixed-column elevation-0">{{	formatNumber(grandTotal[selectedTab].m1_percent_to_target) + '%' }}</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m2_qty) }}</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m2_defiation) + '%'}}</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m2_percent_to_target) + '%' }}</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m0_est_ytd_actual) + '%' }}</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m1_est_ytd_actual) + '%' }}</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m2_est_ytd_actual) + '%' }}</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m0_forecast_accuracy_l3m) + '%' }}</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m1_forecast_accuracy_l3m) + '%' }}</td>
						<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m2_forecast_accuracy_l3m) + '%' }}</td>
					</tr>
				</template>
			</v-data-table>
		</v-card-text>

		<template v-slot:append>
		</template>
	</v-card>

	<v-row class="text-caption mt-2">
		<!-- <v-col>History and Notes</v-col> -->
		<!-- <v-col>
			<v-btn-toggle v-model="toggle" color="primary" variant="text">
				<v-btn height="5vh" size="x-small" density="compact" prepend-icon="mdi-history">History</v-btn>
				<v-btn height="5vh" size="x-small" density="compact" prepend-icon="mdi-note">Notes</v-btn>
			</v-btn-toggle>
		</v-col> -->
	</v-row>
	<v-row class="mt-0 px-4" v-if="toggle === 0">
		<v-timeline align="start" side="end">
			<v-timeline-item dot-color="white" size="x-small" density="compact">
				<div class="d-flex">
					<div class="me-4 text-caption">5pm</div>
					<div>
						<div class="text-caption">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia, eaque.
						</div>
					</div>
				</div>
			</v-timeline-item>

			<v-timeline-item dot-color="white" size="x-small" density="compact">
				<div class="d-flex">
					<div class="me-4 text-caption">5pm</div>
					<div>
						<div class="text-caption">Lorem elit. Quia, eaque.
						</div>
					</div>
				</div>
			</v-timeline-item>


			<v-timeline-item dot-color="white" size="x-small" density="compact">
				<div class="d-flex">
					<div class="me-4 text-caption">5pm</div>
					<div>
						<div class="text-caption">Lorem ipsum dolor sit amet, Quia, eaque.
						</div>
					</div>
				</div>
			</v-timeline-item>
		</v-timeline>
	</v-row>
	<v-row class="mt-0 px-4" v-if="toggle === 1">
		<v-timeline align="start" side="end">
			<v-timeline-item dot-color="white" size="x-small" density="compact">
				<QuillEditor ref="myEditor" theme="snow" toolbar="essential" v-model="notes" />
				<v-btn class="my-2" @click="handleNoteSubmit" :disabled="mode === 0">Post</v-btn>
			</v-timeline-item>

			<v-timeline-item
				v-for="(note, index) in noteList"
				:key="index"
				dot-color="white"
				size="x-small"
				density="compact"
			>
				<div class="d-flex">
					<div class="me-4 text-caption">{{ formatTime(note.created_at) }}</div>
					<div>
						<!-- Render Quill content as HTML -->
						<div class="text-caption" v-html="note.content"></div>
					</div>
				</div>
			</v-timeline-item>
		</v-timeline>
	</v-row>





	<!-- Full Screen code -->
	<v-bottom-sheet v-model="isBottomSheetOpen" fullscreen>
		<v-card>
			<template v-slot:title>
				<v-row>
					<v-tabs v-model="this.selectedTab" bg-color="white" show-arrows
						@update:modelValue="updateTabValue($event)">
						<v-tab v-for="(data, index) in tabsData" :key="data" :text="data.name"
							:value="(data, index)"></v-tab>
					</v-tabs>
				</v-row>

				<v-row>
					<v-col>
						<v-tooltip v-model="tooltipShow" location="end" theme="light">
							<template v-slot:activator="{ props }">
								<v-btn v-bind="props" class="mx-1" color="primary" variant="outlined"
									prepend-icon="mdi-refresh" @click="populateData">
									Refresh Item
								</v-btn>
							</template>
							<span>Please refresh data if table header doesn't load correctly</span>
						</v-tooltip>

						<v-btn class="mx-1" color="primary" variant="outlined" prepend-icon="mdi-view-list-outline">
							List View
						</v-btn>

						<v-btn class="mx-1" color="error" variant="outlined" prepend-icon="mdi-fullscreen"
							@click="isBottomSheetOpen = !isBottomSheetOpen">
							Exit Fullscreen
						</v-btn>
					</v-col>
					<v-spacer></v-spacer>

					<v-col cols="4">
						<v-text-field v-model="search" placeholder="Search" density="compact" append-inner-icon="mdi-magnify"></v-text-field>
					</v-col>
				</v-row>
			</template>

			<v-divider></v-divider>

			<v-card-text>
				<v-data-table v-if="isDataReady && products" hide-default-footer fixed-header :headers="headers"
					:items="products[selectedTab].rolling_forecast_product_details_attributes" item-key="product.id"
					:loading="loading" class="frozen-columns-table" :items-per-page="100" :search="search" >
					<template v-slot:item.index="{ index }">
						{{ index + 1 }}
					</template>

					<template v-slot:item.stock="{ item }">
						<!-- <input :readonly="mode === 0" type="text" v-model.number="item.stock"
							@input="updateValue(item, 'doi')" class="custom-input text-center" /> -->
						<div v-if="mode === 0 || selectedTab === 0" class="text-right">
							{{ formatNumber(item.stock) }}
						</div>
						<div v-else>
							<vue-number v-model="item.stock" v-bind="number" @change="updateValue(item, 'doi')" class="custom-input text-right ma-2"></vue-number>
						</div>
					</template>

					<template v-slot:item.avg_sol3m="{ item }">
						<div class="text-right">
							{{ formatNumber(item.avg_sol3m) }}
						</div>
					</template>

					<template v-slot:item.doi="{ item }">
						<div class="text-right">
							{{ formatNumber(item.doi) }}
						</div>
					</template>

					<!-- Define slot for qty_m0 -->
					<template v-slot:item.m0_qty="{ item }">
						<!-- <input :readonly="mode === 0" type="text" v-model.number="item.m0_qty"
							@input="updateValue(item, 'm0')" class="custom-input text-center" /> -->
						<div v-if="mode === 0 || selectedTab === 0" class="text-right">
							{{ formatNumber(item.m0_qty) }}
						</div>
						<div v-else>
							<vue-number v-model="item.m0_qty" v-bind="number" @change="updateValue(item, 'm0')" class="custom-input text-right py-2"></vue-number>
						</div>
					</template>

					<template v-slot:item.m0_defiation="{ item }">
						<div :class="item.m0_defiation > item.m0_defiation ? 'invalid-mark' : ''" class="text-right">
							{{ formatNumber(item.m0_defiation) + '%' }} 
						</div>
					</template>

					<template v-slot:item.m0_percent_to_target="{ item }">
						<div :class="item.m0_percent_to_target < item.target ? 'invalid-mark' : ''" class="text-right">
							{{ formatNumber(item.m0_percent_to_target) + '%' }} 
						</div>
					</template>

					<template v-slot:item.m0_est_ytd_actual="{ item }">
						{{ formatNumber(item.m0_percent_to_target) + '%' }} 
					</template>

					<template v-slot:item.m0_forecast_accuracy_l3m="{ item }">
						{{ formatNumber(item.lm_forecast_accuracy) + '%' }} 
					</template>

					<!-- Define slot for qty_m1 -->
					<template v-slot:item.m1_qty="{ item }">
						<!-- <input :readonly="mode === 0" type="text" v-model.number="item.m1_qty"
							@input="updateValue(item, 'm1')" class="custom-input text-center" /> -->
						<div v-if="mode === 0 || selectedTab === 0" class="text-right">
							{{ formatNumber(item.m1_qty) }}
						</div>
						<div v-else>
							<vue-number v-model="item.m1_qty" v-bind="number" @change="updateValue(item, 'm1')" class="custom-input text-right py-2"></vue-number>
						</div>
					</template>

					<template v-slot:item.m1_defiation="{ item }">
						<div :class="item.m1_defiation > item.m1_defiation ? 'invalid-mark' : ''" class="text-right">
							{{ formatNumber(item.m1_defiation) + '%' }}  
						</div>
					</template>

					<template v-slot:item.m1_percent_to_target="{ item }">
						<div :class="item.m1_percent_to_target < item.target ? 'invalid-mark' : ''" class="text-right">
							{{ formatNumber(item.m1_percent_to_target) + '%' }}  
						</div>
					</template>

					<template v-slot:item.m1_est_ytd_actual="{ item }">
						{{ formatNumber(item.m1_percent_to_target) + '%' }}  
					</template>

					<template v-slot:item.m1_forecast_accuracy_l3m="{ item }">
						{{ formatNumber(item.l2m_forecast_accuracy) + '%' }} 
					</template>

					<!-- Define slot for qty_m2 -->
					<template v-slot:item.m2_qty="{ item }">
						<!-- <input :readonly="mode === 0" type="text" v-model.number="item.m2_qty"
							@input="updateValue(item, 'm2')" class="custom-input text-center" /> -->
						<div v-if="mode === 0 || selectedTab === 0" class="text-right">
							{{ formatNumber(item.m2_qty) }}
						</div>
						<div v-else>
							<vue-number v-model="item.m2_qty" v-bind="number" @change="updateValue(item, 'm2')" class="custom-input text-right py-2"></vue-number>
						</div>
					</template>

					<template v-slot:item.m2_defiation="{ item }">
						<div :class="item.m2_defiation > item.m2_defiation ? 'invalid-mark' : ''" class="text-right">
							{{ formatNumber(item.m2_defiation) + '%' }}  
						</div>
					</template>

					<template v-slot:item.m2_percent_to_target="{ item }">
						<div :class="item.m2_percent_to_target < item.target ? 'invalid-mark' : ''" class="text-right">
							{{ formatNumber(item.m2_percent_to_target) + '%' }}  
						</div>
					</template>

					<template v-slot:item.m2_est_ytd_actual="{ item }">
						{{ formatNumber(item.m2_percent_to_target) + '%' }}  
					</template>

					<template v-slot:item.m2_forecast_accuracy_l3m="{ item }">
						{{ formatNumber(item.l3m_forecast_accuracy) + '%' }} 
					</template>

					
					<!-- Group SUmmary -->
					<!-- <template v-slot:group-header="{ item, columns, toggleGroup, isGroupOpen }">
						<tr>
							<td :colspan="columns.length">
								<v-btn
									:icon="isGroupOpen(item) ? '$expand' : '$next'"
									size="small"
									variant="text"
									@click="toggleGroup(item)"
								></v-btn>
								<strong>{{ item.value }} - Total Assets: {{ item.items.length }}</strong>
							</td>
						</tr> -->
						<!-- Subtotal Row -->
						<!-- <tr>
							<td></td>
							<td>{{ subTotal(item.items, 'stock') }}</td>
							<td></td>
							<td>{{ subTotal(item.items, 'm0_qty') }}</td>
							<td>{{ subTotal(item.items, 'm1_qty') }}</td>
							<td>{{ subTotal(item.items, 'm2_qty') }}</td>
						</tr>
					</template> -->

					<template v-slot:body.append>
						<tr class="elevation-0">
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td class="fixed-column total-label elevation-0 text-weight-bold">Grand Total</td>
							<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].stock) }}</td>
							<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].avg_sol3m) }}</td>
							<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].doi) + '%'}}</td>
							<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m0_qty) }}</td>
							<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m0_defiation) + '%'}}</td>
							<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m0_percent_to_target) + '%' }}</td>
							<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m1_qty) }}</td>
							<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m1_defiation) + '%' }}</td>
							<td class="fixed-column elevation-0">{{	formatNumber(grandTotal[selectedTab].m1_percent_to_target) + '%' }}</td>
							<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m2_qty) }}</td>
							<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m2_defiation) + '%'}}</td>
							<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m2_percent_to_target) + '%' }}</td>
							<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m0_est_ytd_actual) + '%' }}</td>
							<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m1_est_ytd_actual) + '%' }}</td>
							<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m2_est_ytd_actual) + '%' }}</td>
							<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m0_forecast_accuracy_l3m) + '%' }}</td>
							<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m1_forecast_accuracy_l3m) + '%' }}</td>
							<td class="fixed-column elevation-0">{{ formatNumber(grandTotal[selectedTab].m2_forecast_accuracy_l3m) + '%' }}</td>
						</tr>
					</template>
				</v-data-table>
			</v-card-text>

			<template v-slot:append>
			</template>
		</v-card>
    </v-bottom-sheet>
		<v-overlay
			:model-value="loading"
			class="align-center justify-center"
			persistent
		>
			<v-progress-circular
				color="primary"
				size="120"
				indeterminate
			></v-progress-circular>
		</v-overlay>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';
import { readonly } from 'vue';

export default {
	components: {
		Breadcrumbs
	},

	data() {
		return {
			number: {
				decimal: '.',
				separator: ',',
				prefix: '',
				precision: 2,
				masked: false,
			},
			rollingForecastParams: {
				id: null,
				transaction_number: '',
				transaction_date: '',
				area: '',
				region: '',
				period_id: null,
				transaction_type_id: null,
				coverage_id: null,
				status: '',
				child_coverages: []
			},
			periods: [],
			transactionTypes: [],
			selectedPeriodId: null,
			mainRollingForecastId: null,
			tab: null,
			length: 3,
			headers: [],  // This will be populated dynamically
			isBottomSheetOpen: false,
			toggle: null,
			notes: null,
			tabsData: [],
			headTabsData: [],
			products: [],
			rofoType: '',
			search: '',
			loading: false,
			selectedTab: 0,
			selectedHeadTab: 0,
			tooltipShow: false,
			tableData: [],
			mode: 1, // 0:view 1: input,
			menuButton: [
				{ title: 'Edit' },
				{ title: 'Print' },
				{ title: 'Export' },
				{ title: 'Revise' },
			],
			grandTotal: [
				{
				stock: 0,
				avg_sol3m: 0,
				doi: 0,
				m0_qty: 0,
				m0_defiation: 0,
				m0_percent_to_target: 0,
				m1_qty: 0,
				m1_defiation: 0,
				m1_percent_to_target: 0,
				m2_qty: 0,
				m2_defiation: 0,
				m2_percent_to_target: 0,
				m0_est_ytd_actual: 0,
				m1_est_ytd_actual: 0,
				m2_est_ytd_actual: 0,
				m0_forecast_accuracy_l3m: 0,
				m1_forecast_accuracy_l3m: 0,
				m2_forecast_accuracy_l3m: 0
				}
			],
			asApprover: false,
			groupBy: [
          {
            key: 'product.product_division',
            order: 'asc',
						defaultOpened: true,
          },
        ],
      formulas: {},
			approvedDocument: false
		};
	},
	watch: {
		length(val) {
			this.tab = val - 1
		},
		products: {
			deep: true,
			handler() {
				this.products[this.selectedTab].rolling_forecast_product_details_attributes.forEach(item => {
					if(item){
						this.updateValue(item, 'doi');
						this.updateValue(item, 'm0');
						this.updateValue(item, 'm1');
						this.updateValue(item, 'm2');
						
					}
				});
				
				// this.recalculateTotals(newProd);
				this.calculateGrandTotal();
			}
		}
	},
	created() {
		this.selectedTab = 0;
		this.selectedHeadTab = 0;
	},
	computed: {
		isAdding() {
			return this.$route.params.action === 'add';
		},
		isDataReady() {
			return this.products.length > 0 && !this.loading && this.headers.length > 9;
		},
		breadcrumbItems() {
			const action = this.$route.params.action;
			const route = this.$route.name;
			const items = breadcrumbConfig[route];

			if (typeof items === 'function') {
				return items(action);
			}

			return items || [];
		}
	},
	async mounted() {
    this.loading = true;
		const { action, id, type } = this.$route.params;
		setTimeout(() => this.tooltipShow = false, 3000);

		if (this.isAdding) {
			this.populateData();
		} else {
			// TODO
		}

		if (this.products.length > 0) {
			this.products[selectedTab].rolling_forecast_product_details_attributes.forEach(item => {
				if(item) {
					this.updateValue(item, 'doi');
					this.updateValue(item, 'm0');
					this.updateValue(item, 'm1');
					this.updateValue(item, 'm2');
				}
			});
      		this.calculateGrandTotal();
		}

		// if (type === 'District') {
		// 	this.rofoType = 'Distributor';
		// } else {
		// 	this.rofoType = 'Coverage';
		// }
	},
	methods: {
		...mapActions(['showAlert']),
		async populateData(coverage_id = null, period_id = null) {
			this.loading = true;
			if(coverage_id && period_id) {
				try {
					const response = await axios.get(`/rolling_forecast?coverage_id=${coverage_id}&period_id=${period_id}`);
					this.rollingForecastParams = response.data;
					this.rollingForecastParams.period_id = response.data.period.id;
					this.transactionTypes = response.data.transaction_type;
					this.periods.push(response.data.period);
					this.tabsData = response.data.sub_coverages;
					this.products = response.data.child_coverages;
					this.asApprover = response.data.is_approver;
					this.approvedDocument = response.data.status === "Approved" ? true : false;
					this.selectedTab = 0
				} catch (error) {
					const errorMessage = error.response?.data?.messages
						? error.response.data.messages
						: error.response?.message || 'populate data error';

					this.showAlert({ message: errorMessage, color: 'error' });
					// this.$router.push({ name: 'rofo' });
				} finally {
					if (this.products.length > 0) {
						this.headers = this.generateHeaders(this.products[this.selectedTab]['rolling_forecast_product_details_attributes'])
					}
				}

			} else {
				try {
					const response = await axios.get(`/rolling_forecast/new`);
					this.rollingForecastParams = response.data;
					this.rollingForecastParams.period_id = response.data.period[0].id;
					this.transactionTypes = response.data.transaction_type;
					this.periods = response.data.period;
					this.headTabsData = [...response.data.sub_coverages];
					this.tabsData = [...response.data.sub_coverages];
					this.products = response.data.child_coverages;
					this.asApprover = response.data.is_approver;
					this.approvedDocument = response.data.status === "Approved" ? true : false;
          this.formulas = response.data.formulas;
					this.selectedTab = 0
					// // Setting up rofo type based on rofo new response (coverage)
					this.rofoType = response.data.coverage.coverage_type;
					this.headTabsData.unshift({
						id: response.data.coverage.id,
						type: response.data.coverage.name,
						name: response.data.coverage.name
					});

					if (this.asApprover) {
						this.mainRollingForecastId = response.data.id;
					}

					this.tabsData.unshift({
						id: null,
						type: 'Total',
						name: 'Total'
					});

					let totalDetails = {
						object_id: null, 
						object_type: "Total",
						rolling_forecast_product_details_attributes: []
					};

					let accumulatedTotals = {};
					let productCount = 0; 

					this.products.forEach((product) => {
							if (Array.isArray(product.rolling_forecast_product_details_attributes)) {
									product.rolling_forecast_product_details_attributes.forEach((details) => {
											const productId = details.product_id;

											if (!accumulatedTotals[productId]) {
													accumulatedTotals[productId] = {
															product_id: details.product_id,
															product: details.product,
															stock: 0,
															working_days: 0,
															lm_sell_in: 0,
															l2m_sell_in: 0,
															l3m_sell_in: 0,
															lm_sell_out: 0,
															l2m_sell_out: 0,
															l3m_sell_out: 0,
															m0_target: 0,
															m1_target: 0,
															m2_target: 0,
															m0_defiation_limit: 0,
															m1_defiation_limit: 0,
															m2_defiation_limit: 0,
															m0_qty: 0,
															m1_qty: 0,
															m2_qty: 0,
															lm_qty: 0,
															l2m_qty: 0,
															l3m_qty: 0,
															avg_sol3m: 0,
															doi: 0,
															m0_defiation: 0,
															m1_defiation: 0,
															m2_defiation: 0,
															m0_percent_to_target: 0,
															m1_percent_to_target: 0,
															m2_percent_to_target: 0,
															lm_forecast_accuracy: 0,
															l2m_forecast_accuracy: 0,
															l3m_forecast_accuracy: 0,
													};
											}

											// Accumulate the totals
											Object.keys(accumulatedTotals[productId]).forEach((key) => {
													if (key !== 'product_id' && key !== 'product') {
															accumulatedTotals[productId][key] += Number(details[key] || 0);
													}
											});

											productCount++;
									});
							}
					});

					// Calculate averages for the required attributes
					const averageFields = [
							'avg_sol3m', 'doi', 'm0_defiation', 'm1_defiation', 'm2_defiation',
							'm0_percent_to_target', 'm1_percent_to_target', 'm2_percent_to_target'
					];

					Object.values(accumulatedTotals).forEach((productTotals) => {
							averageFields.forEach((field) => {
									productTotals[field] /= productCount;
							});
					});

					totalDetails.rolling_forecast_product_details_attributes = Object.values(accumulatedTotals);
					this.products.unshift(totalDetails);
					
				} catch (error) {
					console.log(error);
					const errorMessage = error.response?.data?.messages
						? error.response.data.messages
						: error.response?.message || 'error populating data';

					this.showAlert({ message: errorMessage, color: 'error' });
					this.$router.push({ name: 'rofo' });
				} finally {
					if (this.products.length > 0) {
						this.headers = this.generateHeaders(this.products[this.selectedTab]['rolling_forecast_product_details_attributes'])
					}
				}
			}
      this.loading = false;
		},
		async handleSubmit(status) {
			try {
				const rofoData = {
          name: 'ROFO',
					status: status === 0 ? 'Draft' : status === 1 ? 'Awaiting for Approval' : 'Approved',
					id: this.rollingForecastParams.id,
					period_id: this.periods[0].id,
					transaction_type_id: this.rollingForecastParams.transaction_type_id,
					coverage_id: this.rollingForecastParams.coverage.id,
					rolling_forecast_details_attributes: this.products.slice(1), // Slice the  generated Totals Data for input
				};

				if (this.$route.params.action === 'add' && status <= 0) {
					const response = await axios.post('/rolling_forecast', { rolling_forecast: rofoData });

					this.showAlert({ message: 'Rofo data successfully saved', color: 'success' });
					if (response.data) {
						this.mode = 0;
						this.rollingForecastParams = response.data.rolling_forecast;
						// this.transactionTypes = response.data.rolling_forecast.transaction_type;
						// this.periods = response.data.rolling_forecast.period_id;
						this.tabsData = response.data.rolling_forecast.sub_coverages;
						this.products = response.data.rolling_forecast.child_coverages;
					} else {
						throw new Error(response.message);
					}
				} else {
					const updateStatus = status === 1 ? 'submit' : status = 'approve';
					const response = await axios.put(`/rolling_forecast/${this.rollingForecastParams.id}/${updateStatus}`, { rolling_forecast: rofoData });
					this.showAlert({ message: 'Rofo data successfully update', color: 'success' });
					if (response.data) {
						this.mode = 0;
						this.rollingForecastParams = response.data.rolling_forecast;
						this.tabsData = response.data.rolling_forecast.sub_coverages;
						this.products = response.data.rolling_forecast.child_coverages;
					
					} else {
						throw new Error('Failed to update rofo');
					}
				}
			} catch (error) {
				const errorMessage = error.response?.data?.messages
					? error.response.data.messages
					: error.response?.message || 'An error accured';

				this.showAlert({ message: errorMessage, color: 'error' });
			}
		},
		submitForm(status) {
			this.$refs.form.validate();
			this.handleSubmit(status);
		},
		async reviseData() {
			const rofoData = {
					name: 'ROFO',
					status: 'Request for Revise',
					id: this.rollingForecastParams.id,
					period_id: this.periods[0].id,
					transaction_type_id: this.rollingForecastParams.transaction_type_id,
					rolling_forecast_details_attributes: this.products.slice(1), // Slice the  generated Totals Data for input
				};
			try {
				const response = await axios.put(`/rolling_forecast/${this.rollingForecastParams.id}`, { rolling_forecast: rofoData });
				this.showAlert({ message: 'Rofo data successfully update', color: 'success' });
			} catch (error) {
				const errorMessage = error.response?.data?.messages
					? error.response.data.messages
					: error.response?.message || 'An error accured';

				this.showAlert({ message: errorMessage, color: 'error' });
			}
		},
		generateHeaders(data) {
			const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
			// Base headers
			const baseHeaders = [
				{ title: 'NO', value: 'index', align: 'center' },
				{ title: 'PRODUCT DIVISION', value: 'product.product_division', nowrap: true, width: '180px', align: 'left' },
				{ title: 'PRODUCT CAT.1', value: 'product.product_category', nowrap: true, width: '180px', align: 'left' },
				{ title: 'PRODUCT CAT.2', value: 'product.product_category_2', nowrap: true, width: '180px', align: 'left' },
				{ title: 'SKU', value: 'product.sku', align: 'left' },
				{ title: 'PRODUCT NAME', value: 'product.description', nowrap: true, width: '180px', align: 'left' },
				{ title: 'PRODUCT NICKNAME', value: 'product.product_name',nowrap: true, align: 'left' },
				{ title: 'STOCK', value: 'stock', width: '120px', class: 'stock-col', align: 'center' },
				{ title: 'AVG SOL3M', value: 'avg_sol3m', width: '80px', align: 'center' },
				{ title: 'DOI', value: 'doi', width: '80px', align: 'center' },
			];

			// Collect unique periods and results from all items
			const valueEachPeriods = new Set();
			const estForecast = new Set();

			data.forEach(prod => {
				// Extract periods
				valueEachPeriods.add('m0');
				valueEachPeriods.add('m1');
				valueEachPeriods.add('m2');

				estForecast.add('est_ytd_actual');
				estForecast.add('forecast_accuracy_l3m');

			});

			// Convert sets to arrays
			const periodsArray = Array.from(valueEachPeriods);
			const resultsArray = Array.from(estForecast);

			// Generate period headers using the provided logic
			const periods = [];
			if (this.periods && this.periods.length > 0) {
				const startDate = new Date(this.periods[0].date);
				const startMonthIndex = startDate.getMonth();

				for (let i = 0; i < periodsArray.length; i++) {
					const currentMonthIndex = (startMonthIndex + i) % 12;
					const currentMonth = months[currentMonthIndex];
					periods.push(currentMonth);
				}
			}

			// Dynamic headers for periods
			const periodHeaders = periods.map((period, index) => ({
				title: period + ` (M+${index})`,
				align: 'center',
				children: [
					{ title: `QTY`, value: `m${index}_qty`, align: 'center' },
					{ title: `DEFIASI`, value: `m${index}_defiation`, align: 'center' },
					{ title: `% to Target`, value: `m${index}_percent_to_target`, nowrap: false, width: '100px', align: 'center' },
				],
			}));

			// Helper function to get last three months in abbreviated format
			function getLastThreeMonths(startMonthIndex) {
				return [
					months[(startMonthIndex - 3 + 12) % 12], // 3 months ago
					months[(startMonthIndex - 2 + 12) % 12], // 2 months ago
					months[(startMonthIndex - 1 + 12) % 12]  // 1 month ago
				];
			}

			// Calculate the start month index based on the first period in the array
			const firstPeriod = periods[0].toUpperCase(); // Example: "JAN", "FEB"
			const startMonthIndex = months.indexOf(firstPeriod);
			const lastThreeMonths = getLastThreeMonths(startMonthIndex);

			// Dynamic headers for results
			const resultHeaders = resultsArray.map((result, index) => ({
				title: index === 0 ? `Est. YTD Acv` : 'FORECAST ACCURACY L3M',
				align: 'center',
				children: periods.map((period, periodIndex) => ({
					title: index === 0 ? period : lastThreeMonths[periodIndex],
					value: index === 0 ? `m${periodIndex}_est_ytd_actual` : `m${periodIndex}_forecast_accuracy_l3m`,
					align: 'center',
				}))
			}));

			// Combine all headers
			return [
				...baseHeaders,
				...periodHeaders,
				...resultHeaders,
			];
		},
		toggleFullScreen() {
			this.isBottomSheetOpen = true;
		},
		updateTabValue(value) {
			this.selectedTab = value;
		},
		async updateHeadTabValue(value, index) {
			this.selectedHeadTab = index;
			if (index > 0) {
				// need to pass coverage_id and period_id as paramter
				this.populateData(value.id, this.periods[0].id) 
			} else {
				try {
					const response = await axios.get(`/rolling_forecast/new`);
					this.rollingForecastParams = response.data;
					this.rollingForecastParams.period_id = response.data.period[0].id;
					this.transactionTypes = response.data.transaction_type;
					this.periods = response.data.period;
					this.headTabsData = [...response.data.sub_coverages];
					this.tabsData = [...response.data.sub_coverages];
					this.products = response.data.child_coverages;
					this.asApprover = response.data.is_approver;
					this.selectedTab = 0
					// // Setting up rofo type based on rofo new response (coverage)
					this.rofoType = response.data.coverage.coverage_type_id;
					this.headTabsData.unshift({
						id: response.data.coverage.id,
						type: response.data.coverage.name,
						name: response.data.coverage.name
					});

					if (this.asApprover) {
						this.mainRollingForecastId = response.data.id;
					}

					this.tabsData.unshift({
						id: null,
						type: 'Total',
						name: 'Total'
					});

					let totalDetails = {
						object_id: null, 
						object_type: "Total",
						rolling_forecast_product_details_attributes: []
					};

					let accumulatedTotals = {};

					this.products.forEach((product) => {
						if (Array.isArray(product.rolling_forecast_product_details_attributes)) {
							product.rolling_forecast_product_details_attributes.forEach((details) => {
							const productId = details.product_id;

							// Check if totals for this product ID already exist in accumulatedTotals
							if (!accumulatedTotals[productId]) {
								accumulatedTotals[productId] = {
								product_id: details.product_id,
								product: details.product,
								stock: 0,
                working_days: 0,
                lm_sell_in: 0,
                l2m_sell_in: 0,
                l3m_sell_in: 0,
                lm_sell_out: 0,
                l2m_sell_out: 0,
                l3m_sell_out: 0,
                m0_target: 0,
                m1_target: 0,
                m2_target: 0,
                m0_defiation_limit: 0,
                m1_defiation_limit: 0,
                m2_defiation_limit: 0,
                m0_qty: 0,
                m1_qty: 0,
                m2_qty: 0,
                lm_qty: 0,
                l2m_qty: 0,
                l3m_qty: 0,
                avg_sol3m: 0,
                doi: 0,
                m0_defiation: 0,
                m1_defiation: 0,
                m2_defiation: 0,
                m0_percent_to_target: 0,
                m1_percent_to_target: 0,
                m2_percent_to_target: 0,
                lm_forecast_accuracy: 0,
                l2m_forecast_accuracy: 0,
                l3m_forecast_accuracy: 0,
								};
							}

							accumulatedTotals[productId].stock += Number(details.stock);
              accumulatedTotals[productId].working_days += Number(details.working_days);
              accumulatedTotals[productId].lm_sell_in += Number(details.lm_sell_in);
              accumulatedTotals[productId].l2m_sell_in += Number(details.l2m_sell_in);
              accumulatedTotals[productId].l3m_sell_in += Number(details.l3m_sell_in);
              accumulatedTotals[productId].lm_sell_out += Number(details.lm_sell_out);
              accumulatedTotals[productId].l2m_sell_out += Number(details.l2m_sell_out);
              accumulatedTotals[productId].l3m_sell_out += Number(details.l3m_sell_out);
              accumulatedTotals[productId].m0_target += Number(details.m0_target);
              accumulatedTotals[productId].m1_target += Number(details.m1_target);
              accumulatedTotals[productId].m2_target += Number(details.m2_target);
              accumulatedTotals[productId].m0_defiation_limit += Number(details.m0_defiation_limit);
              accumulatedTotals[productId].m1_defiation_limit += Number(details.m1_defiation_limit);
              accumulatedTotals[productId].m2_defiation_limit += Number(details.m2_defiation_limit);
              accumulatedTotals[productId].m0_qty += Number(details.m0_qty);
              accumulatedTotals[productId].m1_qty += Number(details.m1_qty);
              accumulatedTotals[productId].m2_qty += Number(details.m2_qty);
              accumulatedTotals[productId].lm_qty += Number(details.lm_qty);
              accumulatedTotals[productId].l2m_qty += Number(details.l2m_qty);
              accumulatedTotals[productId].l3m_qty += Number(details.l3m_qty);
              accumulatedTotals[productId].avg_sol3m += Number(details.avg_sol3m);
              accumulatedTotals[productId].doi += Number(details.doi);
              accumulatedTotals[productId].m0_defiation += Number(details.m0_defiation);
              accumulatedTotals[productId].m1_defiation += Number(details.m1_defiation);
              accumulatedTotals[productId].m2_defiation += Number(details.m2_defiation);
              accumulatedTotals[productId].m0_percent_to_target += Number(details.m0_percent_to_target);
              accumulatedTotals[productId].m1_percent_to_target += Number(details.m1_percent_to_target);
              accumulatedTotals[productId].m2_percent_to_target += Number(details.m2_percent_to_target);
              accumulatedTotals[productId].lm_forecast_accuracy += Number(details.lm_forecast_accuracy);
              accumulatedTotals[productId].l2m_forecast_accuracy += Number(details.l2m_forecast_accuracy);
              accumulatedTotals[productId].l3m_forecast_accuracy += Number(details.l3m_forecast_accuracy);
							});
						}
					});

					totalDetails.rolling_forecast_product_details_attributes = Object.values(accumulatedTotals);

					this.products.unshift(totalDetails);
					
				} catch (error) {
					console.log(error);
					const errorMessage = error.response?.data?.messages
						? error.response.data.messages
						: error.response?.message || 'error di awal';

					this.showAlert({ message: errorMessage, color: 'error' });
					this.$router.push({ name: 'rofo' });
				} finally {
					if (this.products.length > 0) {
						this.headers = this.generateHeaders(this.products[this.selectedTab]['rolling_forecast_product_details_attributes'])
					}
				}
			}
			
		},
    calculateResult(variables, formula) {
      // Extract variable keys and values
      const variableKeys = Object.keys(variables);
      const variableValues = Object.values(variables);

      // Construct a dynamic function with variable keys as parameters
      const fn = new Function(...variableKeys, `return ${formula};`);

      // Call the function with variable values
      const result = fn(...variableValues);

      // Check if the result is a number
      if (typeof result === 'number' && isFinite(result) && !isNaN(result)) {
        return result.toFixed(0);  // Rounds the result to the nearest integer
      } else {
		return "0";
      }
    },
		updateValue(item, type) {
			switch (type) {
				case 'doi':
					item.doi = this.calculateResult({stock: item.stock, avg_sol3m: item.avg_sol3m, working_days: item.working_days}, this.formulas.doi_formula);
          // TODO: PLEASE PUT ON THE RIGHT PLACE
          item.lm_forecast_accuracy = this.calculateResult({lm_qty: item.lm_qty, lm_sell_in: item.lm_sell_in}, this.formulas.lm_forecast_accuracy_formula);
          item.l2m_forecast_accuracy = this.calculateResult({l2m_qty: item.l2m_qty, l2m_sell_in: item.l2m_sell_in}, this.formulas.l2m_forecast_accuracy_formula);
          item.l3m_forecast_accuracy = this.calculateResult({l3m_qty: item.l3m_qty, l3m_sell_in: item.l3m_sell_in}, this.formulas.l3m_forecast_accuracy_formula);
					break;
				case 'm0':
          item.m0_defiation = this.calculateResult({lm_qty: item.lm_qty, m0_qty: item.m0_qty}, this.formulas.m0_defiation_formula);
          item.m0_percent_to_target = this.calculateResult({m0_qty: item.m0_qty, m0_target: item.m0_target}, this.formulas.m0_percent_to_target_formula);
					break;
				case 'm1':
          item.m1_defiation = this.calculateResult({m0_qty: item.m0_qty, m1_qty: item.m1_qty}, this.formulas.m1_defiation_formula);
          item.m1_percent_to_target = this.calculateResult({m1_qty: item.m1_qty, m1_target: item.m1_target}, this.formulas.m1_percent_to_target_formula);
					break;
				case 'm2':
          item.m2_defiation = this.calculateResult({m1_qty: item.m1_qty, m2_qty: item.m2_qty}, this.formulas.m2_defiation_formula);
          item.m2_percent_to_target = this.calculateResult({m2_qty: item.m2_qty, m2_target: item.m2_target}, this.formulas.m2_percent_to_target_formula);
					break;
			}
		},
		calculateGrandTotal() {
      	this.grandTotal = this.tabsData.map((tab, index) => {
				const products = this.products[index].rolling_forecast_product_details_attributes;

				if(products) {
					const totals = {
					tabName: tab.name,  // Assuming tabsData has a name field
					stock: 0,
					avg_sol3m: 0,
					doi: 0,
					m0_qty: 0,
					m0_defiation: 0,
					m0_percent_to_target: 0,
					m1_qty: 0,
					m1_defiation: 0,
					m1_percent_to_target: 0,
					m2_qty: 0,
					m2_defiation: 0,
					m2_percent_to_target: 0,
					m0_est_ytd_actual: 0,
					m1_est_ytd_actual: 0,
					m2_est_ytd_actual: 0,
					m0_forecast_accuracy_l3m: 0,
					m1_forecast_accuracy_l3m: 0,
					m2_forecast_accuracy_l3m: 0,
					};

					products.forEach((item) => {
						totals.stock += Number(item.stock) || 0;
						totals.avg_sol3m += Number(item.avg_sol3m) || 0;
						totals.doi += Number(item.doi) || 0;
						totals.m0_qty += Number(item.m0_qty) || 0;
						totals.m0_defiation += Number(item.m0_defiation) || 0;
						totals.m0_percent_to_target += Number(item.m0_percent_to_target) || 0;
						totals.m1_qty += Number(item.m1_qty) || 0;
						totals.m1_defiation += Number(item.m1_defiation) || 0;
						totals.m1_percent_to_target += Number(item.m1_percent_to_target) || 0;
						totals.m2_qty += Number(item.m2_qty) || 0;
						totals.m2_defiation += Number(item.m2_defiation) || 0;
						totals.m2_percent_to_target += Number(item.m2_percent_to_target) || 0;
						totals.m0_est_ytd_actual = Number(item.m0_percent_to_target) || 0;
						totals.m1_est_ytd_actual = Number(item.m1_percent_to_target) || 0;
						totals.m2_est_ytd_actual = Number(item.m2_percent_to_target) || 0;
						totals.m0_forecast_accuracy_l3m += Number(item.m0_forecast_accuracy_l3m) || 0;
						totals.m1_forecast_accuracy_l3m += Number(item.m1_forecast_accuracy_l3m) || 0;
						totals.m2_forecast_accuracy_l3m += Number(item.m2_forecast_accuracy_l3m) || 0;
					});

					if (products.length > 0) {
						totals.avg_sol3m = totals.avg_sol3m / products.length;
						totals.doi = totals.doi / products.length;
						totals.m0_defiation = totals.m0_defiation / products.length;
						totals.m1_defiation = totals.m1_defiation / products.length;
						totals.m2_defiation = totals.m2_defiation / products.length;
						totals.m0_percent_to_target = totals.m0_percent_to_target / products.length;
						totals.m1_percent_to_target = totals.m1_percent_to_target / products.length;
						totals.m2_percent_to_target = totals.m2_percent_to_target / products.length;
					}

					return totals;
				}
			
			});
		},
		formatNumber(value) {
			const number = Number(value) || 0;
			return new Intl.NumberFormat('en-US', {
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			}).format(number);
		},
		updateMode() {
			this.mode = 1;
			this.$route.params.action = 'update'
		},
		async handleNoteSubmit() {
			try {
				const noteData = {
					content: this.$refs.myEditor.getHTML(),
					noteable_type: 'RollingForecast',
					noteable_id: this.rollingForecastParams.id
				};

				const response = await axios.post('/note', { note: noteData });

				if (response.data) {
					this.fetchNotes(this.rollingForecastParams.id)
					this.showAlert({ message: 'Successfully save Note', color: 'success' });
				} else {
					this.showAlert({ message: "Failed", color: 'error' });
				}
				
			} catch (error) {
				const errorMessage = error.response?.data?.messages
				? error.response.data.messages
				: error.response?.message || 'An error occurred';

				this.showAlert({ message: errorMessage, color: 'error' });
			}
		},
		subTotal(items, column) {
			if (!Array.isArray(items)) {
				console.warn('Expected an array, but got:', items);
				return 0; // or another default value
			}

			return items.reduce((sum, item) => {
				console.log(`Current item:`, item);
				console.log(`Value of ${column}:`, item['columns'][column]);
				return sum + (item[column] || 0); // Add a fallback for undefined values
			}, 0);
		},

	}
};
</script>

<style scoped>
.custom-input {
	border: 1px solid #ddd;
	padding: 6px 6px;
	border-radius: 2px;
	width: 5vw;
	box-sizing: border-box;
	text-align: right;
}

.custom-input:focus {
	border-color: #1976d2;
	outline: none;
}

/* Apply the same fixed positioning to the Total row's fixed columns */
.fixed-column {
	position: sticky;
	background-color: white;
	z-index: 1;
	text-align: right;
	font-weight: bold;
}

/* Apply specific width for each fixed column to match headers */
.total-label {
	width: 100px;
	text-align: center;
	/* Adjust this to match the width of the first column */
}

.fixed-column:nth-child(2) {
	left: 100px;
	/* Adjust based on the width of the first fixed column */
}

/* Ensure the sticky columns stay in place when scrolling */
tr>.fixed-column {
	box-shadow: 2px 0px 5px -2px rgba(0, 0, 0, 0.2);
}

.invalid-mark {
	background: #FFCDD2;
}


::v-deep .v-table.v-table--fixed-header > .v-table__wrapper > table > thead > tr > th {
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
}

::v-deep .v-table .v-table__wrapper > table > tbody > tr:not(:last-child) > td {
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
}

::v-deep .v-table > .v-table__wrapper > table > tbody > tr > td {
	border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
}

.v-table {
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
}


</style>