import RofoOpeningBalanceForm from "@/views/RofoOpeningBalanceForm.vue";
import RollingForecastConfiguration from "@/views/RollingForecastConfiguration.vue";

export default {
    user: [
        { title: 'Home', href: '/home' },
        { title: 'Users', disabled: true, href: '' }
    ],
    'user-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Users', href: '/home/user' },
        { title: action === 'edit' ? 'Edit User' : 'Add User', disabled: true, href: '' }
    ],
    'user-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Users', href: '/home/user' },
        { title: 'View', disabled: true, href: '' }
    ],
    forecast: [
        { title: 'Home', href: '/home' },
        { title: 'Forcast', disabled: true, href: '' }
    ],
    profile: [
        { title: 'Home', href: '/home' },
        { title: 'Profile', disabled: true, href: '' }
    ],
    role: [
        { title: 'Home', href: '/home' },
        { title: 'Role', disabled: true, href: '' }
    ],
    'role-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Role', href: '/home/role' },
        { title: action === 'edit' ? 'Edit Role' : 'Add Role', disabled: true, href: '' }
    ],
    'role-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Role', href: '/home/role' },
        { title: 'View', disabled: true, href: '' }
    ],
    approval: [
        { title: 'Home', href: '/home' },
        { title: 'Approval', disabled: true, href: '' }
    ],
    'approval-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Approval', href: '/home/approval' },
        { title: action === 'edit' ? 'Edit Approval' : 'Add Approval', disabled: true, href: '' }
    ],
    'approval-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Approval', href: '/home/approval' },
        { title: 'View', disabled: true, href: '' }
    ],
    transactionType: [
        { title: 'Home', href: '/home' },
        { title: 'Transaction Type', disabled: true, href: '' }
    ],
    'transactionType-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Transaction Type', href: '/home/transactionType' },
        { title: action === 'edit' ? 'Edit Transaction Type' : 'Add Transaction Type', disabled: true, href: '' }
    ],
    coverage: [
        { title: 'Home', href: '/home' },
        { title: 'Coverage', disabled: true, href: '' }
    ],
    'coverage-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Coverage', href: '/home/coverage' },
        { title: action === 'edit' ? 'Edit Coverage' : 'Add Coverage', disabled: true, href: '' }
    ],
    rofo: [
        { title: 'Home', href: '/home' },
        { title: 'Rofo', disabled: true, href: '' }
    ],
    'rofo-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Rofo', href: '/home/rofo' },
        { title: action === 'edit' ? 'Edit RoFo' : 'Add RoFo', disabled: true, href: '' }
    ],
    'rofo-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Rofo', href: '/home/rofo' },
        { title: 'View', disabled: true, href: '' }
    ],
    cmo: [
        { title: 'Home', href: '/home' },
        { title: 'CMO', disabled: true, href: '' }
    ],
    'cmo-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'CMO', href: '/home/cmo' },
        { title: action === 'edit' ? 'Edit CMO' : 'Add CMO', disabled: true, href: '' }
    ],
    product: [
        { title: 'Home', href: '/home' },
        { title: 'Product', disabled: true, href: '' }
    ],
    'product-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Product', href: '/home/product' },
        { title: action === 'edit' ? 'Edit Product' : 'Add Product', disabled: true, href: '' }
    ],
    'product-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Product', href: '/home/product' },
        { title: 'View', disabled: true, href: '' }
    ],
    productDivision: [
        { title: 'Home', href: '/home' },
        { title: 'Product Division', disabled: true, href: '' }
    ],
    'productDivision-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Product Division', href: '/home/productDivision' },
        { title: action === 'edit' ? 'Edit Product Division' : 'Add Product Division', disabled: true, href: '' }
    ],
    'productDivision-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Product Division', href: '/home/productDivision' },
        { title: 'View', disabled: true, href: '' }
    ],
    productCategory: [
        { title: 'Home', href: '/home' },
        { title: 'Product Category', disabled: true, href: '' }
    ],
    'productCategory-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Product Category', href: '/home/productCategory' },
        { title: action === 'edit' ? 'Edit Product Category' : 'Add Product Category', disabled: true, href: '' }
    ],
    'productCategory-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Product Category', href: '/home/productCategory' },
        { title: 'View', disabled: true, href: '' }
    ],
    price: [
        { title: 'Home', href: '/home' },
        { title: 'Price', disabled: true, href: '' }
    ],
    'price-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Price', href: '/home/price' },
        { title: action === 'edit' ? 'Edit Price' : 'Add Price', disabled: true, href: '' }
    ],
    'price-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Price', href: '/home/price' },
        { title: 'View', disabled: true, href: '' }
    ],
    uom: [
        { title: 'Home', href: '/home' },
        { title: 'UOM', disabled: true, href: '' }
    ],
    'uom-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'UOM', href: '/home/uom' },
        { title: action === 'edit' ? 'Edit UOM' : 'Add UOM', disabled: true, href: '' }
    ],
    'uom-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'UOM', href: '/home/uom' },
        { title: 'View', disabled: true, href: '' }
    ],
    distributor: [
        { title: 'Home', href: '/home' },
        { title: 'Distributor', disabled: true, href: '' }
    ],
    'distributor-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Distributor', href: '/home/distributor' },
        { title: action === 'edit' ? 'Edit Distributor' : 'Add Distributor', disabled: true, href: '' }
    ],
    'distributor-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Distributor', href: '/home/distributor' },
        { title: 'View', disabled: true, href: '' }
    ],
    period: [
        { title: 'Home', href: '/home' },
        { title: 'Period', disabled: true, href: '' }
    ],
    'period-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Period', href: '/home/period' },
        { title: action === 'edit' ? 'Edit Period' : 'Add Period', disabled: true, href: '' }
    ],
    'period-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Period', href: '/home/period' },
        { title: 'View', disabled: true, href: '' }
    ],
    salesTarget: [
        { title: 'Home', href: '/home' },
        { title: 'Data Target', disabled: true, href: '' }
    ],
    'salesTarget-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Data Target', href: '/home/salesTarget' },
        { title: action === 'edit' ? 'Edit Data Target' : 'Add Data Target', disabled: true, href: '' }
    ],
    'salesTarget-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Data Target', href: '/home/salesTarget' },
        { title: 'View', disabled: true, href: '' }
    ],
    defiasiLimit: [
        { title: 'Home', href: '/home' },
        { title: 'Defiasi Limit', disabled: true, href: '' }
    ],
    'defiasiLimit-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Defiasi Limit', href: '/home/defiasiLimit' },
        { title: action === 'edit' ? 'Edit Defiasi Limit' : 'Add Defiasi Limit', disabled: true, href: '' }
    ],
    'defiasiLimit-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Defiasi Limit', href: '/home/defiasiLimit' },
        { title: 'View', disabled: true, href: '' }
    ],
    sellOut: [
        { title: 'Home', href: '/home' },
        { title: 'Sell Out', disabled: true, href: '' }
    ],
    'sellOut-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Sell out', href: '/home/sellOut' },
        { title: action === 'edit' ? 'Edit Sell out' : 'Add Sell out', disabled: true, href: '' }
    ],
    'sellOut-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Sell out', href: '/home/sellOut' },
        { title: 'View', disabled: true, href: '' }
    ],
    sellIn: [
        { title: 'Home', href: '/home' },
        { title: 'Sell In', disabled: true, href: '' }
    ],
    'sellIn-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Sell In', href: '/home/sellIn' },
        { title: action === 'edit' ? 'Edit Sell In' : 'Add Sell In', disabled: true, href: '' }
    ],
    'sellIn-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Sell In', href: '/home/sellIn' },
        { title: 'View', disabled: true, href: '' }
    ],
    'approvalNeeded': [
        { title: 'Home', href: '/home' },
        { title: 'Approval Needed', disabled: true, href: '' }
    ],
    'approvalNeeded-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Approval Needed', href: '/home/approvalNeeded' },
        { title: 'View', disabled: true, href: '' }
    ],
    'notification': [
        { title: 'Home', href: '/home' },
        { title: 'Notification', disabled: true, href: '' }
    ],
    'notification-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Notification', href: '/home/notification' },
        { title: 'View', disabled: true, href: '' }
    ],
    vehicle: [
        { title: 'Home', href: '/home' },
        { title: 'Vehicle', disabled: true, href: '' }
    ],
    'vehicle-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Vehicle', href: '/home/vehicle' },
        { title: action === 'edit' ? 'Edit Vehicle' : 'Add Vehicle', disabled: true, href: '' }
    ],
    'vehicle-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Vehicle', href: '/home/vehicle' },
        { title: 'View', disabled: true, href: '' }
    ],
    'calendar-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Calendar Manager', href: '/home/calendar' },
        { title: action === 'edit' ? 'Edit Calendar' : 'Add Events', disabled: true, href: '' }
    ],
    'calendar': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Calendar', disabled: true, href: '' }
    ],
    'promotion-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Promotion Manager', href: '/home/promotion' },
        { title: action === 'edit' ? 'Edit Promotion' : 'Add Promotion', disabled: true, href: '' }
    ],
    'promotion': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Promotion', disabled: true, href: '' }
    ],
    productGroup: [
        { title: 'Home', href: '/home' },
        { title: 'Product Group', disabled: true, href: '' }
    ],
    'productGroup-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Product Group', href: '/home/productGroup' },
        { title: action === 'edit' ? 'Edit Product Group' : 'Add Product Group', disabled: true, href: '' }
    ],
    'productGroup-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Product Group', href: '/home/productGroup' },
        { title: 'View', disabled: true, href: '' }
    ],
    rollingForecastConfiguration: [
        { title: 'Home', href: '/home/rollingForecastConfiguration' },
        { title: 'Rolling Forecast Configuration', disabled: true, href: '' }
    ],
    rofoOpeningBalance: [
        { title: 'Home', href: '/home' },
        { title: 'Rofo Opening Balance', disabled: true, href: '' }
    ],
    'rofoOpeningBalance-form': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Rofo Opening Balance', href: '/home/rofoOpeningBalance' },
        { title: action === 'edit' ? 'Edit Rofo Opening Balance' : 'Add Rofo Opening Balance', disabled: true, href: '' }
    ],
    'rofoOpeningBalance-view': (action) => [
        { title: 'Home', href: '/home' },
        { title: 'Rofo Opening Balance', href: '/home/rofoOpeningBalance' },
        { title: 'View', disabled: true, href: '' }
    ],
  };
  