<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title> </template>

    <template v-slot:append>
      <v-btn class="text-none" color="primary" @click="submitForm">
        {{ isEditMode ? 'Update' : 'Save' }}
      </v-btn>
    </template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form @submit.prevent="submitForm">
          <v-row>
            <v-col lg="2" md="2" sm="12" cols="12">
              <label class="custom-label">Role Name</label>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="roleName"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="12" cols="12">
              <label class="custom-label">Status</label>
            </v-col>
            <v-col cols="12" md="6">
              <v-select v-model="status" :items="statusOptions"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="12" cols="12">
              <label class="custom-label">Main / Submenu</label>
            </v-col>
            <v-col cols="12" md="6">
              <v-select v-model="selectedModule" :items="moduleOptions" multiple></v-select>
            </v-col>
            <v-col cols="4" class="d-flex align-center">
              <v-btn @click="addModule" color="primary" class="align-self-center">Add</v-btn>
            </v-col>
          </v-row>
          <v-divider class="my-4"></v-divider>
          <v-row>
            <v-col cols="12">
              <v-data-table items-per-page="999" :headers="headers" :items="modules" item-value="name"
                hide-default-footer>
                <template v-slot:item.name="{ item }">
                  {{ formatModuleName(item.name) }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn variant="text" density="compact" @click="confirmDelete(item)" class="pa-0" color="error">
                    {{ $t('actions.delete') }}
                  </v-btn>
                </template>
                <template v-slot:item.permission="{ item }">
                  <div class="permission-wrapper">
                    <v-checkbox v-for="permission in item.permissions" :key="permission.value" :label="permission.label"
                      v-model="item.permissionsChecked[permission.value]">
                    </v-checkbox>
                  </div>
                </template>

              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card-text>
  </v-card>

  <!-- Confirmation Dialog -->
  <v-dialog v-model="showConfirmation" max-width="400" persistent>
    <v-card>
      <v-card-title>
        Confirm Deletion
      </v-card-title>
      <v-card-subtitle>
        Are you sure you want to delete this item?
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="showConfirmation = false">
          Cancel
        </v-btn>
        <v-btn @click="removeModule(moduleToDelete)">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
  components: {
    Breadcrumbs,
  },
  computed: {
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    },
    isEditMode() {
      return !!this.$route.params.id;
    }
  },
  data() {
    return {
      roleName: '',
      status: '',
      selectedModule: null,
      statusOptions: ['Active', 'Inactive'],
      moduleOptions: [
        'Role',
        'Users',
        'Approval',
        'Coverage',
        'Coverage Type',
        'Transaction Type',
        'Product',
        'UOM',
        'Product Division',
        'Product Category',
        'Product Group',
        'Price',
        'Distributor',
        'Rolling Forecast',
        'Period',
        'Approval Step',
        'Approval History',
        'Vehicle',
        'Calendar Manager',
        'Promotion',
        'Selling Out',
        'Selling In',
        'Sales Target',
        'Defiation Limit',
        'Rolling Forecast Configuration',
        'Rofo Opening Balance',
        'Confirm Monthly Order',
      ], // TODO: THIS APPROACH SHOULD BE REFACTORED !!
      modules: [],
      actionData: [],
      headers: [
        { title: 'Module', key: 'name' },
        { title: 'Permission', key: 'permission' },
        { title: 'Action', key: 'actions', sortable: false },
      ],
      showConfirmation: false,
      moduleToDelete: null
    };
  },
  watch: {
    // selectedModule(newModule) {
    //   if (newModule) {
    //     this.fetchAction(newModule);
    //   }
    // }
    selectedModule(newModules) {
    if (newModules && newModules.length > 0) {
        this.actionData = [];
        // Loop through each selected module
        newModules.forEach(module => {
          this.fetchAction(module);
        });
      }
    }
  },
  async mounted() {
    const { id } = this.$route.params;
    if (id) {
      try {
        const response = await axios.get(`/role/${id}`);
        const role = response.data;

        this.roleName = role.name;
        this.status = role.status;
        await this.initializeModules(role.permissions);
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'get role failed';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    // async fetchAction(module) {
    //   try {
    //     // Transform the module parameter
    //     const formattedModule = module.toLowerCase().replace(/\s+/g, '_');

    //     const response = await axios.get('/role/available_permissions', {
    //       params: { module: formattedModule }
    //     });

    //     this.actionData = response.data.permissions_available.methods.map(method => {
    //       const key = Object.keys(method)[0];
    //       if (formattedModule === 'users' && key == 'create') {
    //         return { value: `${formattedModule}/registrations/${key}`, label: method[key].label };
    //       }else {
    //         return { value: `${formattedModule}/${key}`, label: method[key].label };
    //       }
    //     });
    //   } catch (error) {
    //     console.log(error)
    //     const errorMessage = error.response?.data?.messages
    //       ? error.response.data.messages
    //       : error.response?.message || 'An error A';

    //     this.showAlert({ message: errorMessage, color: 'error' });
    //   }
    // },
    async fetchAction(module) {
      try {
        // Transform the module parameter
        const formattedModule = module.toLowerCase().replace(/\s+/g, '_');

        const response = await axios.get('/role/available_permissions', {
          params: { module: formattedModule },
        });

        // Store the actions for this specific module
        const moduleActions = response.data.permissions_available.methods.map(method => {
          const key = Object.keys(method)[0];
          // if (formattedModule === 'users' && key == 'create') {
          //   return { value: `${formattedModule}/registrations/${key}`, label: method[key].label };
          // } else {
            return { value: `${formattedModule}/${key}`, label: method[key].label };
          // }
        });

        // Assign the actions to the specific module name in actionData
        this.actionData[formattedModule] = moduleActions; // Direct assignment
      } catch (error) {
        console.log(error);
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'Failed fetching module';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },

    addModule() {
      if (this.selectedModule && this.selectedModule.length > 0) {
        this.selectedModule.forEach(module => {
          if (!this.modules.some(m => m.name === module)) {
            const formattedModule = module.toLowerCase().replace(/\s+/g, '_');
            const newPermissions = this.actionData[formattedModule] || []; // Use the specific module's actions
            const permissionsChecked = newPermissions.reduce((acc, curr) => {
              acc[curr.value] = false; // Initialize all permissions as unchecked
              return acc;
            }, {});

            // Push the new module with its permissions
            this.modules.push({
              name: module,
              permissions: newPermissions,
              permissionsChecked: permissionsChecked,
            });
          }
        });
        this.selectedModule = []; // Reset the selectedModule array
      }
    },
    confirmDelete(module) {
      this.showConfirmation = true;
      this.moduleToDelete = module;
    },
    removeModule(module) {
      this.modules = this.modules.filter(m => m.name !== module.name);
      this.showConfirmation = false;
    },
    async submitForm() {
      const allPermissions = this.modules.flatMap(module =>
        Object.keys(module.permissionsChecked)
          .filter(permission => module.permissionsChecked[permission])
          .map(permission => `api/${permission}`)
      );

      const roleData = {
        role: {
          name: this.roleName,
          status: this.status,
          permissions: allPermissions,
        }
      };
      
      try {
        const { id } = this.$route.params;
        const url = id ? `/role/${id}` : '/role';
        const method = id ? 'put' : 'post';
        const response = await axios[method](url, roleData);
        method == 'put' ?
          this.showAlert({ message: 'Role successfully update', color: 'success' })
          : this.showAlert({ message: 'Role successfully saved', color: 'success' })

        this.$router.push({ name: 'role' });
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'Submit error';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async initializeModules(permissions) {
      // Extract unique module names from permissions
      const modules = [...new Set(permissions.map(permission => permission.split('/')[1]))];
      
      // Fetch permissions for each module and store them in a dictionary
      const modulePermissionsMap = {};
      await Promise.all(modules.map(async (module) => {
        await this.fetchAction(module); // Fetch permissions for the module
        modulePermissionsMap[module] = this.actionData[module] || []; // Store only the fetched actions for this module
      }));

      // Map the modules to the correct permissions
      this.modules = modules.map(module => {
        const modulePermissions = modulePermissionsMap[module]; // Get permissions for the current module
        const permissionsChecked = modulePermissions.reduce((acc, curr) => {
          acc[curr.value] = permissions.includes('api/' + curr.value); // Check if the permission is in the provided permissions
          return acc;
        }, {});

        return {
          name: module,
          permissions: modulePermissions,
          permissionsChecked: permissionsChecked
        };
      });
    },
    formatModuleName(moduleName) {
      if (/^[A-Z]/.test(moduleName)) {
        return moduleName;
      }
      
      return moduleName
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }

  }
};
</script>

<style>
.v-data-table__wrapper {
  overflow-x: auto;
}

.permission-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.permission-wrapper .v-checkbox {
  margin-right: 10px;
}
</style>
