<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <!-- <v-btn color="primary" @click="navigateToForm('add')">{{ $t('button.newApprovalNeeded') }}</v-btn> -->
      </v-col>
    </v-row>
    <v-row>
      <!-- <v-col>
          <v-chip closable>
            Create data from console to see the UI example by clicking message item
          </v-chip>
      </v-col> -->
      <v-col cols="12">
        <data-table :headers="headers" :items="approvalNeeded" :actions="tableActions" :loading="loading" :mobile="isMobile"/>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import axios from '../plugins/axios';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import breadcrumbConfig from '@/others/breadcrumbConfig';
  import { mapActions } from 'vuex';
  import DataTable from '@/components/DataTable.vue';
  
  export default {
    components: {
      Breadcrumbs,
      DataTable
    },
    data() {
      return {
        headers: [
          { title: 'Type', key: 'type', align: 'start', sortable: false, clickable: true },
          { title: 'Transaction Number', value: 'document.transaction_number', align: 'start', sortable: false },
          { title: 'Status', value: 'status', align: 'start', sortable: false }
        ],
        approvalNeeded: [],
        tableActions: {
          delete: this.deleteApprovalNeeded,
          view: this.navigateToForm
        },
        loading: false,
        isMobile: false,
      };
    },
    computed: {
      breadcrumbItems() {
        const route = this.$route.name;
        const action = this.$route.params.action;
        const breadcrumbItems = breadcrumbConfig[route];
  
        // Handle case where the route is dynamic, e.g., 'approvalNeeded-form'
        if (typeof breadcrumbItems === 'function') {
          return breadcrumbItems(action);
        }
  
        return breadcrumbItems || [];
      }
    },
    watch: {
      '$vuetify.display.xs'(newVal) {
        this.isMobile = newVal;
      },
    },
    methods: {
      ...mapActions(['showAlert']),
      async fetchApprovalNeeded() {
        this.loading = true;
        try {
          const response = await axios.get('/approval_step');
          this.approvalNeeded = response.data;
          this.loading = false;
        } catch (error) {
          this.loading = false;
          const errorMessage = error.response?.data?.messages 
                              ? error.response.data.messages 
                              : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      navigateToForm(action, approvalNeeded = { id: null }) {
        if (action == 'view') {
          this.$router.push({ name: 'approvalNeeded-view', params: { action, id: approvalNeeded.id } });
        } else {
          this.$router.push({ name: 'approvalNeeded-form', params: { action, id: approvalNeeded.id } });
        }
      },
      async deleteApprovalNeeded(approvalNeededToDelete) {
        try {
          const response = await axios.delete(`/approval_step/${approvalNeededToDelete.id}`);
  
          if (response.data) {
            this.approvalNeeded = this.approvalNeeded.filter(u => u.id !== approvalNeededToDelete.id);
  
            this.showAlert({ message: 'Success', color: 'success' });
          } else {
            throw new Error('Failed to delete approvalNeeded');
          }
        } catch (error) {
          const errorMessage = error.response?.data?.messages 
                              ? error.response.data.messages 
                              : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      }
    },
    mounted() {
      this.fetchApprovalNeeded();
    }
  };
  </script>
  