<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card elevation="0" class="mt-2">
    <template v-slot:append>
      <v-btn class="text-none" color="primary" @click="goToCustomizePage('edit', user)">
        Customize
      </v-btn>
    </template>

    <v-divider></v-divider>

    <v-card-text>
      <!-- Email -->
      <v-row>
        <v-col lg="2" md="2" sm="2" cols="12">
          <label class="custom-label">Email</label>
        </v-col>
        <v-col md="6" sm="6" cols="12">
          <div>{{ user.email }}</div>
        </v-col>
      </v-row>

      <!-- Role -->
      <v-row>
        <v-col lg="2" md="2" sm="2" cols="12">
          <label class="custom-label">Role</label>
        </v-col>
        <v-col md="6" sm="6" cols="12">
          <div>{{ roleName }}</div>
        </v-col>
      </v-row>

       <!-- Status -->
       <v-row>
        <v-col lg="2" md="2" sm="2" cols="12">
          <label class="custom-label">Status</label>
        </v-col>
        <v-col md="6" sm="6" cols="12">
          <div>{{ user.status }}</div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';
import { mapActions } from 'vuex';

export default {
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      user: {
        id: null,
        email: '',
        status: ''
      },
      roleName: '',
      roles: [],
    };
  },
  computed: {
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    },
  },
  async mounted() {
    const { id } = this.$route.params;

    // Fetch user details
    if (id) {
      try {
        const response = await axios.get(`/users/${id}`);
        this.user = response.data;

        // Fetch role name based on role_id
        this.fetchRoleName(this.user.role.id);
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async fetchRoleName(roleId) {
      try {
        const response = await axios.get(`/role/${roleId}`);
        this.roleName = response.data.name;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    goToCustomizePage(action, user) {
      this.$router.push({ name: 'user-form', params: { action, id: user.id } });
    },
  },
};
</script>

<style scoped>
.custom-label {
  font-weight: bold;
  font-size: 11px;
}
</style>