<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title> </template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row class="my-2">
            <v-col cols="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Distributor (Sold-To)</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-select v-model="distributor.parent_id" :items="parentDistributors" item-value="id" item-title="name"
                    placeholder="Select Sold To" required clearable></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6"></v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="my-2">
            <v-col cols="6" md="6">
              <v-row>
                <v-col md="3" sm="3" cols="12">
                  <label class="custom-label">Distribution Type</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-select v-model="distributor.distribution_type" :items="distributionTypes"
                    placeholder="" required></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" md="6">
              <v-row>
                <v-col md="3" sm="3" cols="12">
                  <label class="custom-label">Distributor Code</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="distributor.code" required></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Distributor Name</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="distributor.name" required></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" md="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Distributor Short Name</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="distributor.short_name" required></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Address</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-textarea rows="5" v-model="distributor.address" required></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" md="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Region</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field readonly v-model="distributor.region" 
                    placeholder="" required></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Area</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field readonly v-model="distributor.area"
                    placeholder="" required></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">District</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-select v-model="distributor.coverage" :items="coveragesData" item-value="id" item-title="name"
                    placeholder="" required @update:modelValue="districtSelected"></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" md="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Country</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field readonly rows="3" v-model="distributor.country" required></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Phone</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="distributor.phone" required></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" md="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Fax</label>
                </v-col>
                <v-col md="6" sm="6" cols="12">
                  <v-text-field v-model="distributor.fax" required></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">CMO Shipment Type</label>
                </v-col>
                <v-col md="6" sm="6" cols="12" class="py-2">
                  <v-checkbox
                    v-model="distributor.cmo_shipment_type"
                    label="Full Shipment"
                    value="Full Shipment"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="distributor.cmo_shipment_type"
                    label="Multi Drop"
                    value="Multi Drop"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="distributor.cmo_shipment_type"
                    label="Single Drop"
                    value="Single Drop"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" md="6">
              <v-row>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <label class="custom-label">Status</label>
                </v-col>
                <v-col md="6" sm="6" cols="12" class="py-2">
                  <v-radio-group
                    v-model="distributor.status"
                    inline
                    hide-details
                  >
                    <v-radio
                      label="Active"
                      value="Active"
                    ></v-radio>
                    <v-radio
                      label="Inactive"
                      value="Inactive"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div v-if="distributor.parent_id">
          <v-row class="my-4">
            <v-col cols="12">
              <div>Sales Area Supervisor List</div>
            </v-col>
            <v-col cols="12">
              <v-data-table :items="sales" :headers="headers" hide-default-footer >
                <template v-slot:item.user_id="{ item }">
                  <v-select v-model="item.user_id" :items="usersData" item-value="id" item-title="email" class="py-3"
                    placeholder="select sales" required></v-select>
                </template>
                <template v-slot:item.period_active="{ item }">
                  <v-row>
                    <v-col cols="6">
                      <v-date-input
                        v-model="item.period_active.start_date"
                        placeholder="Start Date"
                        variant="outlined"
                        density="compact"
                        :multiple="false"
                        prepend-inner-icon="null"  
                      ></v-date-input>
                    </v-col>
                    <v-col cols="6">
                      <v-date-input
                        v-model="item.period_active.end_date"
                        placeholder="End Date"
                        variant="outlined"
                        density="compact"
                        :multiple="false"
                      ></v-date-input>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-select v-model="item.status" :items="statuses" class="py-3"
                    placeholder="" required></v-select>
                </template>
                <template v-slot:item.actions="{ item }"> <!-- Use item.actions here -->
                  <v-btn density="compact" variant="text" color="error"
                    @click="confirmDelete(item, 1)">{{ $t('actions.delete') }}</v-btn>
                </template>
              </v-data-table>
            </v-col>
            <v-col>
              <v-btn variant="outlined" color="primary" @click="addNewSalesList">Add Another</v-btn>
            </v-col>
          </v-row>

          <v-row class="my-4">
            <v-col cols="12">
              <div>Vehicle List</div>
            </v-col>
            <v-col cols="12">
              <v-data-table :items="distributor_vehicle" :headers="vehicleHeaders" hide-default-footer >
                <template v-slot:item.vehicle_id="{ item }">
                  <v-select v-model="item.vehicle_id" :items="vehiclesData" item-value="id" item-title="vehicle_name" class="py-3"
                    placeholder="Vehicle Name" required></v-select>
                </template>
                <template v-slot:item.fulfillment_limit="{ item }">
                  <v-text-field v-model="item.fulfillment_limit" :items="statuses" class="py-3"
                    placeholder="Fulfillment Limit" required></v-text-field>
                </template>
                <template v-slot:item.actions="{ item }"> <!-- Use item.actions here -->
                  <v-btn density="compact" variant="text" color="error"
                    @click="confirmDelete(item, 2)">{{ $t('actions.delete') }}</v-btn>
                </template>
              </v-data-table>
            </v-col>
            <v-col>
              <v-btn variant="outlined" color="primary" @click="addNewVehicleList">Add Another</v-btn>
            </v-col>
          </v-row>
        </div>
        </v-form>
      </div>
    </v-card-text>

    <template v-slot:append>
      <v-btn @click="submitForm" color="primary">
        {{ isAdding ? $t('button.save') : $t('button.update') }}
      </v-btn>
    </template>
  </v-card>

  <!-- Confirmation Dialog -->
  <v-dialog v-model="showConfirmation" max-width="400" persistent>
            <v-card>
                <v-card-title>
                    Confirm Deletion
                </v-card-title>
                <v-card-subtitle>
                    Are you sure you want to delete this item?
                </v-card-subtitle>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="showConfirmation = false">
                        Cancel
                    </v-btn>
                    <v-btn @click="deleteItem">
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';
import { VDateInput } from 'vuetify/labs/VDateInput'

export default {
  components: {
    Breadcrumbs,
    VDateInput
  },
  data() {
    return {
      distributor: {
        id: null,
        name: '',
        code: '',
        distribution_type: '',
        short_name: '',
        address: '',
        phone: '',
        fax: '',
        status: 'Active',
        coverage: null,
        parent_id: null,
        cmo_shipment_type: [],
      },
      sales: [],
      distributor_vehicle: [],
      distributionTypes :[ 'GT', 'MT'],
      coveragesData: [],
      usersData: [],
      vehiclessData: [],
      parentDistributors: [],
      headers: [
        { title: 'Sales', key: 'user_id', align: 'center', width:400 },
        { title: 'Period Active', key: 'period_active', align: 'center' },
        { title: 'Status', key: 'status', align: 'center' },
        { title: 'Action', value: 'actions', align: 'center' },
      ],
      vehicleHeaders :[
        { title: 'Vehicle Type', value: 'vehicle_id', align: 'center' },
        { title: 'Fulfillment Limit', key: 'fulfillment_limit', align: 'center' },
        { title: 'Action', value: 'actions', align: 'center' },
      ],
      statuses: ['Active', 'Waiting'],
      showConfirmation: false,
      itemToDelete: null,
      itemNeedToDelete: null,
    };
  },
  computed: {
    isAdding() {
      return this.$route.params.action === 'add';
    },
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    }
  },
  async mounted() {
    const { action, id } = this.$route.params;

    this.fetchCoverages()
    this.fetchUsers()
    this.fetchVehicles()
    // Fetch parent distributor
    try {
      const response = await axios.get('/distributor?is_parent=true');
      this.parentDistributors = response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.messages
        ? error.response.data.messages
        : error.response?.message || 'An error occurred';

      this.showAlert({ message: errorMessage, color: 'error' });
    }

    // Fetch distributor details if editing
    if (action === 'edit' && id) {
      try {
        const response = await axios.get(`/distributor/${id}`);
        this.distributor = response.data;
        this.sales = response.data.sales_area_supervisors.map(sales => ({
                        ...sales,
                        period_active: {
                          ...sales.period_active,
                          start_date: new Date(sales.period_active.start_date), 
                          end_date: new Date(sales.period_active.end_date)   
                        }
                      }));
        this.distributor_vehicle = [...response.data.distributor_vehicles];
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async handleSubmit() {
      try {
        
        const distributorData = {
          parent_id: this.distributor.parent_id,
          name: this.distributor.name,
          code: this.distributor.code,
          short_name: this.distributor.short_name,
          distribution_type: this.distributor.distribution_type,
          phone: this.distributor.phone,
          fax: this.distributor.fax,
          address: this.distributor.address,
          coverage_id: this.distributor.coverage,
          status: this.distributor.status,
          cmo_shipment_type: this.distributor.cmo_shipment_type,
          sales_area_supervisors_attributes: this.sales.map(sales => ({
                                              ...sales,
                                              period_active: {
                                                ...sales.period_active,
                                                start_date: sales.period_active.start_date 
                                                  ? sales.period_active.start_date.toLocaleDateString('en-CA') 
                                                  : null,
                                                end_date: sales.period_active.end_date 
                                                  ? sales.period_active.end_date.toLocaleDateString('en-CA') 
                                                  : null
                                              }
                                            })),
          distributor_vehicle_attributes: this.distributor_vehicle
        };

        if (this.isAdding) {
          const response = await axios.post('/distributor/', { distributor: distributorData });

          if (response.data) {
            this.$router.push({ name: 'distributor' });
            this.showAlert({ message: 'Successfully added Distributor data', color: 'success' });
          } else {
            this.showAlert({ message: "Failed", color: 'error' });
          }
        } else {
          const response = await axios.put(`/distributor/${this.distributor.id}`, { distributor: distributorData });

          if (response.data) {
            this.$router.push({ name: 'distributor' });
            this.showAlert({ message: 'Successfully added Distributor data', color: 'success' });
          } else {
            this.showAlert({ message: 'Error updating Distributor', color: 'error' });
          }
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    submitForm() {
      this.$refs.form.validate(); // Trigger form validation
      this.handleSubmit(); // Call the submit method
    },
    async fetchCoverages() {
      try {
        // const response = await axios.get('/coverage?type=district');
        const response = await axios.get('/coverage');
        this.coveragesData = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchUsers() {
      try {
        const response = await axios.get('/users');
        this.usersData = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchVehicles() {
      try {
        const response = await axios.get('/vehicle');
        this.vehiclesData = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    addNewSalesList() {
      let newSales = {
        user_id: null,
        period_active: {
          start_date: null,
          end_date: null,
        },
        status: 'Active'
      }

      this.sales.push(newSales);
    },
    addNewVehicleList() {
      let newVehicle = {
        vehicle_id: null,
        fulfillment_limit: null
      }

      this.distributor_vehicle.push(newVehicle);
    },
    confirmDelete(item, type) {
      this.itemToDelete = item;
      this.itemNeedToDelete = type;
      this.showConfirmation = true;
    },
    deleteItem() {
      if (this.itemNeedToDelete === 1) {
        const index = this.sales.indexOf(this.itemToDelete);
        if (index !== -1) {
          this.sales.splice(index, 1); 
        }
      }
      else {
        const index = this.distributor_vehicle.indexOf(this.itemToDelete);
        if (index !== -1) {
          this.distributor_vehicle.splice(index, 1); 
        }
      }
      this.showConfirmation = false;
      this.itemToDelete = null;
    },
    districtSelected() {
      let district = this.coveragesData.find(coverage => coverage.id === this.distributor.coverage)

      this.distributor.area = district.parent.name;
      this.distributor.region = district.parent.parent.name;
      this.distributor.country = district.parent.parent.parent.name;
    }
  }
};
</script>

<style scoped>
.v-data-table {
  border: 1px solid gainsboro !important;
}
</style>
