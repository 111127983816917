<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-card>
      <template v-slot:title> </template>
  
      <v-divider></v-divider>
  
      <v-card-text>
        <v-timeline align="start" side="end">
          <v-timeline-item dot-color="white" size="small">
            <template v-slot:icon>
              1
            </template>
            <div> Download our Sell Out template file</div>
            <div class="text-caption text-weight-light ml-1">
              Start by downloading our sell out CSV (Comma Separated Values) template file. This file has the correct
              column
              headings system needs to import your sell out data.
            </div>
            <v-btn class="my-2" variant="text" color="primary">Download Template File</v-btn>
          </v-timeline-item>
  
          <v-timeline-item dot-color="white" size="x-small" density="compact">
            <template v-slot:icon>
              2
            </template>
            <div> Copy your Sell Out Data into the template</div>
            <div class="text-caption text-weight-light ml-1">
              Start by downloading our sell out CSV (Comma Separated Values) template file. This file has the correct
              column
              headings system needs to import your sell out data.
            </div>
          </v-timeline-item>
  
  
          <v-timeline-item dot-color="white" size="x-small" density="compact">
            <template v-slot:icon>
              3
            </template>
            <div> Import the updated template file</div>
            <div class="text-caption text-weight-light ml-1">
              Select the file to import
            </div>
            <div>
              <!-- <v-file-input density="compact" clearable label="File input" variant="outlined" class="mt-2"></v-file-input> -->
              <v-file-input v-model="file" density="compact" clearable label="File input" variant="outlined" class="mt-2"
                @change="handleFileChange" />
                <!-- <input type="file" @change="handleFileChange" accept=".csv, .xls, .xlsx" /> -->
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
  
      <template v-slot:append>
        <v-btn @click="submitForm" color="primary">
          Import
        </v-btn>
        <v-btn @click="submitForm" color="error" class="ml-2">
          Cancel
        </v-btn>
      </template>
    </v-card>
    <v-overlay
      v-if="loading"
      :model-value="loading"
      class="align-center justify-center"
      persistent
    >
      <v-progress-circular
        color="primary"
        size="120"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </template>
  
  <script>
  import axios from '../plugins/axios';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import { mapActions } from 'vuex';
  import breadcrumbConfig from '@/others/breadcrumbConfig.js';
  
  export default {
    components: {
      Breadcrumbs
    },
    data() {
      return {
        sellIn: {
          file: null,
        },
        file: null,
        notes: null,
        coverageTypes: [],
        roles: [],
        selectedRoleId: null,
        selectedCoverageId: null,
        loading:false,
      };
    },
    computed: {
      isAdding() {
        return this.$route.params.action === 'add';
      },
      breadcrumbItems() {
        const action = this.$route.params.action;
        const route = this.$route.name;
        const items = breadcrumbConfig[route];
  
        if (typeof items === 'function') {
          return items(action);
        }
  
        return items || [];
      }
    },
    async mounted() {
      const { action, id } = this.$route.params;
      // Fetch sellIn details if 
    },
    methods: {
      ...mapActions(['showAlert', 'showLoading', 'hideLoading']),
      async submitForm() {
        this.showLoading();
        this.loading = true;
        if (!this.file) {
          this.showAlert({ message: 'Please select a file before submitting', color: 'error' });
          return;
        }
  
        const formData = new FormData();
        formData.append('record_type', 'SellingIn');
        formData.append('file', this.file);
  
        try {
          const response = await axios.post('/selling_in/upload',  formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
  
          if (response.data) {
            this.showAlert({ message: 'File successfully imported', color: 'success' });
            this.$router.push({ name: 'sellIn' });
          } else {
            throw new Error('Failed to import file');
          }
        } catch (error) {
          const errorMessage = error.response?.data?.message
          ? error.response.data.message
            : error.message || 'An error occurred';
          this.showAlert({ message: errorMessage, color: 'error' });
          this.hideLoading();
          this.loading = false;
        }
        this.hideLoading();
        this.loading = false;
      },
      cancel() {
        this.file = null; // Clear the file input
        this.$router.push({ name: 'sellIn' });
      },
      handleFileChange(event) {
        this.file = event.target.files[0];
      },
    }
  };
  </script>