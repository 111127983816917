<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-row>
    <v-col cols="12" class="d-flex justify-end">
      <v-btn color="primary" @click="navigateToForm('add')">{{ $t('button.newDistributor') }}</v-btn>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <data-table :headers="headers" :items="distributor" :actions="tableActions" :loading="loading" />
    </v-col>
  </v-row>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig';
import { mapActions } from 'vuex';
import DataTable from '@/components/DataTable.vue';

export default {
  components: {
    Breadcrumbs,
    DataTable
  },
  data() {
    return {
      headers: [
        { title: 'Name', key: 'name', align: 'start', clickable: true },
        { title: 'Code', key: 'code', align: 'start' },
        { title: 'Alias', key: 'short_name', align: 'start' },
        { title: 'Coverage', value: 'coverage.name', align: 'start' },
        { title: 'Status', key: 'status', align: 'start' },
        { title: '', key: 'actions', align: 'end', sortable: false },
      ],
      distributor: [],
      tableActions: {
        edit: this.navigateToForm,
        delete: this.deleteProductDivision,
        view: this.navigateToForm
      },
      loading: false
    };
  },
  computed: {
    breadcrumbItems() {
      const route = this.$route.name;
      const action = this.$route.params.action;
      const breadcrumbItems = breadcrumbConfig[route];

      // Handle case where the route is dynamic, e.g., 'distributor-form'
      if (typeof breadcrumbItems === 'function') {
        return breadcrumbItems(action);
      }

      return breadcrumbItems || [];
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async fetchDistributor() {
      this.loading = true;
      try {
        const response = await axios.get('/distributor');
        this.distributor = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    navigateToForm(action, distributor = { id: null }) {
      if (action == 'view') {
        this.$router.push({ name: 'distributor-view', params: { action, id: distributor.id } });
      } else {
        this.$router.push({ name: 'distributor-form', params: { action, id: distributor.id } });
      }
    },
    async deleteProductDivision(distributorToDelete) {
      try {
        const response = await axios.delete(`/distributor/${distributorToDelete.id}`);

        if (response.data) {
          this.distributor = this.distributor.filter(u => u.id !== distributorToDelete.id);

          this.showAlert({ message: 'Success', color: 'success' });
        } else {
          throw new Error('Failed to delete distributor');
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  mounted() {
    this.fetchDistributor();
  }
};
</script>