<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title> </template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Distributor (Sold To)</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-select v-model="parentDistributorId" :items="parentDistributors" item-value="id" item-title="name"
                    placeholder="" required clearable></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Distributor (Ship To)</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-select v-model="price.distributor" :items="distributors" return-object item-title="name"
                    placeholder="" required clearable></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Distribution Type</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-text-field readonly v-model="distributorType"
                placeholder=""></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="12" cols="12">
                <label class="custom-label">Period Active</label>
            </v-col>
            <v-col cols="6" md="3">
                <v-date-input :disabled="isEditMode" v-model="price.start_date" placeholder="Start Date" variant="outlined" density="compact"
                    :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
            </v-col>
            <v-col cols="6" md="3">
                <v-date-input :disabled="isEditMode" v-model="price.end_date" placeholder="End Date" variant="outlined" density="compact"
                    :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table :items="price.price_details_attributes" :headers="productHeaders" hide-default-footer >
                <template v-slot:item.price_value="{ item }">
                  <v-text-field v-model="item.price_value" class="py-3"
                    placeholder="Price"></v-text-field>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card-text>

    <template v-slot:append>
      <v-btn @click="submitForm" color="primary">
        {{ isAdding ? $t('button.save') : $t('button.update') }}
      </v-btn>
    </template>
  </v-card>
</template>

<script>6
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
  components: {
    Breadcrumbs
  },
  data() {
    return {
      price: {
        id: null,
        price_1: null,
        price_2: null,
        price_3: null,
        transaction_code: null,
        distributor: null,
        price_details_attributes: []
      },
      parentDistributors: [],
      distributors: [],
      parentDistributorId: null,
      productHeaders :[
        { title: 'Product Code', value: 'product_code', align: 'center' },
        { title: 'Product Division', key: 'product_division.name', align: 'center' },
        { title: 'Product Name', key: 'product_name', align: 'center' },
        { title: 'Product Nickname', key: 'product_nickname', align: 'center' },
        { title: 'Price Value', value: 'price_value', align: 'center' },
      ],
      products: []
    };
  },
  computed: {
    isAdding() {
      return this.$route.params.action === 'add';
    },
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    },
    distributorType() {
      return this.price.distributor ? this.price.distributor.distribution_type : 'Select Distributor First';
    },
  },
  async mounted() {
    const { action, id } = this.$route.params;

    this.fetchParentDistributor();
    this.fetchDistributors();

    await this.fetchProducts(); 

    // Fetch price details if editing
    if (action === 'edit' && id) {
      try {
        const response = await axios.get(`/price/${id}`);
        this.price.id = response.data.id;
        this.price.distributor = response.data.distributor;
        this.parentDistributorId = response.data.distributor.parent_id;
        this.price.start_date = new Date(response.data.start_date);
        this.price.end_date = new Date(response.data.end_date);
        this.price.price_details_attributes = response.data.price_details;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async handleSubmit() {
      try {
        const priceData = {
          start_date: this.price.start_date.toLocaleDateString('en-CA') ,
          end_date: this.price.end_date.toLocaleDateString('en-CA') ,  
          distributor_id: this.price.distributor.id,
          price_details_attributes: this.price.price_details_attributes
        };
        if (this.isAdding) {
          const response = await axios.post('/price/', { price: priceData });

          if (response.data) {
            this.$router.push({ name: 'price' });
            this.showAlert({ message: 'Successfully added Price data', color: 'success' });
          } else {
            this.showAlert({ message: "Failed", color: 'error' });
          }
        } else {
          const response = await axios.put(`/price/${this.price.id}`, { price: priceData });

          if (response.data) {
            this.$router.push({ name: 'price' });
            this.showAlert({ message: 'Successfully added Price data', color: 'success' });
          } else {
            this.showAlert({ message: 'Error updating Price', color: 'error' });
          }
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    submitForm() {
      this.$refs.form.validate(); // Trigger form validation
      this.handleSubmit(); // Call the submit method
    },
    async fetchParentDistributor() {
      try {
        const response = await axios.get('/distributor?is_parent=true');
        this.parentDistributors = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchDistributors() {
      try {
        const response = await axios.get('/distributor?is_parent=false');
        this.distributors = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchProducts() {
      try {
        const response = await axios.get('/product');
        this.products = response.data;
        this.products.forEach(product => {
          this.price.price_details_attributes.push({
            product_id: product.id,
            product_code: product.sku,
            product_division: product.product_division_id,
            product_name: product.product_name,
            product_nickname: product.alias_name,
            price_value: null
          });
        });
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
  }
};
</script>