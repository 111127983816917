<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-row>
    <v-col cols="12" class="d-flex justify-end">
      <v-btn color="primary" @click="navigateToForm('add')">{{ $t('button.newRolePermission') }}</v-btn>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <data-table :headers="headers" :items="roles" :actions="tableActions" />
    </v-col>
  </v-row>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig';
import { mapActions } from 'vuex';
import DataTable from '@/components/DataTable.vue';

export default {
  components: {
    Breadcrumbs,
    DataTable
  },
  data() {
    return {
      headers: [
        { title: 'Name', key: 'name', align: 'start', clickable: true },
        { title: 'Status', key: 'status', align: 'start' },
        { title: '', key: 'actions', align: 'end', sortable: false },
      ],
      roles: [],
      tableActions: {
        edit: this.navigateToForm,
        delete: this.deleteRole,
        view: this.navigateToForm
      }
    };
  },
  computed: {
    breadcrumbItems() {
      const route = this.$route.name;
      const action = this.$route.params.action;
      const breadcrumbItems = breadcrumbConfig[route];

      // Handle case where the route is dynamic, e.g., 'role-form'
      if (typeof breadcrumbItems === 'function') {
        return breadcrumbItems(action);
      }

      return breadcrumbItems || [];
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async fetchRoles() {
      try {
        const response = await axios.get('/role');
        this.roles = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    navigateToForm(action, role = { id: null }) {
      if (action == 'view') {
        this.$router.push({ name: 'role-view', params: { action, id: role.id } });
      } else {
        this.$router.push({ name: 'role-form', params: { action, id: role.id } });
      }
    },
    async deleteRole(roleToDelete) {
      try {
        const response = await axios.delete(`/role/${roleToDelete.id}`);

        if (response.status === 200) {
          this.roles = this.roles.filter(role => role.id !== roleToDelete.id);

          this.showAlert({ message: 'Role deleted successfully', color: 'success' });
        } else {
          throw new Error('Failed to delete role');
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }

  },
  mounted() {
    this.fetchRoles();
  }
};
</script>